import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import Icons from '../icons'

// interface MenuItem {
//   value: string
//   label: string
// }
interface SelectDropdownProps {
  tileItem?: any

  setUpdateCREId?: any
  unAllocate?: boolean
  getData?: (search: string, page: number) => any
  disabled?: boolean
}
const DynamicDropdown: React.FC<SelectDropdownProps> = ({
  tileItem,

  setUpdateCREId,
  getData,
  unAllocate,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [srch, setSrch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [pg, setPg] = useState<number>(0)
  const [hasNext, setHasNext] = useState(false)
  const [dropdown, setDropdown] = useState<any>([])
  const [menuValue, setMenuValue] = useState(
    tileItem?.value
      ? tileItem?.value
      : (tileItem?.placeholder ?? tileItem?.label)
  )
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleMenuItemClick = (item?: any) => {
    setUpdateCREId?.(item?.id ?? null)
    setMenuValue(item?.value ?? '')
    setIsOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const fetchAddonData = () => {
    getData?.(srch, pg).then((res: any) => {
      setHasNext(res.hasNext)
      setDropdown([...dropdown, ...res.data])
    })
  }
  useEffect(() => {
    if (pg > 0) {
      fetchAddonData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pg, srch])

  useEffect(() => {
    setPg(1)
  }, [])
  const handleSearchChange = (e: any) => {
    setSearchKey(e.target.value)
    manageSearch(e.target.value)
  }
  const manageSearch = debounce((key = '') => {
    setDropdown([])
    setHasNext(false)
    setSrch(key)
    setPg(1)
  }, 200)
  const handlePageChange = () => {
    setPg((p) => p + 1)
  }

  const generateShortLabel = (v?: string) => {
    let shortName = '--'
    if (v && v !== '') {
      const splittedName = v.split(' ')
      if (splittedName?.length >= 2) {
        shortName = `${splittedName[0]?.charAt(0)}${splittedName[1]?.charAt(0)}`
      } else {
        shortName = `${splittedName[0]?.charAt(0)}${splittedName[0]?.charAt(0)}`
      }
    }
    return shortName
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center gap-1 h-[22px] px-1.5 py-[3px] rounded-3xl bg-white cursor-pointer"
        onClick={() => {
          disabled ? null : setIsOpen(!isOpen)
        }}
      >
        <div className="w-4 h-4 rounded-full flex items-center justify-center bg-[#bdbdbd] text-white text-[8px] leading-4">
          {generateShortLabel(tileItem?.value || menuValue)}
        </div>
        <p className=" text-secondary normal-case text-xxs leading-4 font-medium">
          {tileItem?.value ? tileItem?.value : menuValue}
        </p>
        <Icons name="drop-arrow" className="tileIconWidth-xs iconBlack" />
      </div>
      {isOpen && (
        <div className="menuopened">
          <div className=" max-h-[300px] overflow-y-auto flex flex-col gap-1">
            {getData && (
              <input
                placeholder="search"
                className="sticky top-0  outline-none  w-full textfield"
                onChange={handleSearchChange}
                value={searchKey ?? ''}
              />
            )}

            {dropdown?.map((item: any, ind: number) => (
              <div
                className="menuopened-item"
                key={`${ind}-${new Date().toDateString()}`}
                onClick={() => handleMenuItemClick(item)}
              >
                {item.label ?? item.value}
              </div>
            ))}
            {hasNext && (
              <span
                className="bg-transparent py-1.5 px-2 cursor-pointer text-linkBlue w-full"
                onClick={() => {
                  handlePageChange()
                }}
              >
                Load more
              </span>
            )}
          </div>
          {!unAllocate && (
            <>
              <div className="w-full h-px bg-formBorder"></div>
              <div
                onClick={() => {
                  handleMenuItemClick()
                }}
                className="menuopened-item text-error"
              >
                Un Allocate
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default DynamicDropdown
