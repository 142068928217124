import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { FieldViewProps } from '../../../common/types'
import Icons from '../../../components/common/icons'
import { useAppStore } from '../../../store/appStore'
export const getParsedValue = (val?: string) => {
  let newVal: any = []
  if (val) {
    newVal = val?.split('\n')

    return (
      <>
        {newVal.map((item: string, i: number) => (
          <p key={i}>
            {item}
            <br />
          </p>
        ))}
      </>
    )
  }

  return '--'
}
const FormFieldView: React.FC<FieldViewProps> = ({
  label,
  fullwidth = true,
  required = false,
  image = false,
  link,
  value,
  hidden,
  type = 'text',
}) => {
  const { isLoading } = useAppStore()

  const [showPassword, setShowPassword] = useState(false)
  const handleHtml = (val: any) => {
    // Check if rawContent has the required structure
    if (!val) {
      return '--'
    }

    const htmlContent = val
    return htmlContent
  }
  return (
    <>
      {hidden ? null : (
        <div className={fullwidth ? 'w-full' : 'w-auto'}>
          {isLoading ? (
            <div className=" grid gap-3 ">
              <div className=" w-10 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className=" w-auto h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
          ) : (
            <>
              {label && (
                <div className="relative ">
                  <label className={`labels label-text`}>
                    {label}
                    {required ? <span className="text-error"> *</span> : <></>}
                  </label>
                  {type == 'password' && (
                    <button
                      type="button"
                      className="absolute right-2 z-10"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Icons name="eye" />
                      ) : (
                        <Icons name="eye-close" />
                      )}
                    </button>
                  )}
                </div>
              )}
              <div className={`relative flex items-center`}>
                {link ? (
                  image ? (
                    <img src={link} width={100} alt="" />
                  ) : (
                    <Link
                      to={link}
                      target="_blank"
                      className=" font-medium text-sm text-link break-all"
                    >
                      {' '}
                      {value ? value : '--'}
                    </Link>
                  )
                ) : type === 'text_editor' ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: handleHtml(value) }}
                  />
                ) : (
                  <p className=" font-medium text-sm text-primaryText dark:text-white break-all  whitespace-break-spaces">
                    {type == 'password'
                      ? showPassword
                        ? value
                        : '#####'
                      : type === 'textarea'
                        ? getParsedValue(value)
                        : value
                          ? value
                          : '--'}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default FormFieldView
