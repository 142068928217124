import React from 'react'

import { TabItemProps, TabProps } from '../../../common/types'
import { checkMultiplePermission } from '../../../configs/permissionGate'

interface WrapperProps {
  children: React.ReactNode
  activeTab: string | number
}
interface ActiveTabProps {
  activeTab: string | number
}
const Wrapper: React.FC<WrapperProps> = ({ children, activeTab }) => {
  return (
    <div className="wrapper">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            activeTab: activeTab,
          } as Partial<ActiveTabProps>)
        }
        return child
      })}
    </div>
  )
}

const TabContainer: React.FC<TabProps> = ({
  data,
  onClick,
  children,

  activeTab,
}) => {
  const generateClassName = (tab: TabItemProps): string => {
    let generatedClassName = 'w-max text-sm leading-6  font-medium '
    if (tab.id === activeTab) {
      generatedClassName +=
        ' text-primary  font-bold cursor-default border-[#E76458]'
    } else {
      if (tab.disabled) {
        generatedClassName +=
          ' text-primaryText dark:text-white cursor-not-allowed border-transparent'
      } else {
        generatedClassName +=
          ' text-primaryText dark:text-white cursor-pointer border-transparent'
      }
    }
    return generatedClassName
  }
  const handleClick = (item: TabItemProps) => {
    if (!item.disabled) {
      onClick(item)
    }
  }
  return (
    <div className="w-full ">
      <div className="w-full overflow-auto  tab-scroll flex px-4  gap-2 border-b border-grey-light ">
        {data.map((tab: TabItemProps) => (
          <>
            {checkMultiplePermission(tab?.id as string) && !tab.hidden && (
              <div
                key={tab.id}
                className={` transition-all duration-100 p-2.5 ${generateClassName(
                  tab
                )}  border-b-2`}
                onClick={() => {
                  handleClick(tab)
                }}
              >
                <p className="w-max ">{tab.label}</p>
              </div>
            )}
          </>
        ))}
      </div>
      <div className="">
        <div className="tab-section bg-white dark:bg-[#394150] p-4 rounded">
          <Wrapper activeTab={activeTab}>{children}</Wrapper>
        </div>
      </div>
    </div>
  )
}

export default TabContainer
