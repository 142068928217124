const LOGIN_URL = 'employee/login/'
const ACCOUNT_LIST_URL = 'customer/companies/'
const CONTACT_LIST_URL = 'customer/'
const EXPORT_LIST_URL = 'employee/exports/'
const EMPLOYEE_LIST_URL = 'employee/'
const NOTIFICATION_TYPE = '/core/notification-type'
const EMPLOYEE_EXPORT = 'employee/list/export/'

const TEAMS_LIST_URL = 'employee/teams/'
const TEAM = 'employee/team/'
const ROLE_LIST_URL = 'user/groups/'
const USER_URL = 'user/'
const MY_TASKS_LIST_URL = 'crm/employee/tasks'
const MY_APPROVALS_LIST_URL = 'employee/approval-requests'
const SERVICE_PAYMENT_APPROVAL = 'employee/service-payment-approval-request'
const MY_APPROVALS_LIST_EXPORT = 'employee/approval-requests/export'
const APPROVALS_STATUS_LIST_URL = '/core/approval-statuses'
const MY_APPROVALS_URL = '/employee/approval-requests/'
const MY_APPROVALS_URL_EXCEPT_PAYMENT =
  'employee/approval-requests/?approval_type__code=upload_deliverables&approval_type__code=others&approval_type__code=tl_approval&approval_type__code=task_completion_approval'
const ORDER_SERVICES_URL = '/sales/orders/order-services'
const ORDER_LIST_URL = '/sales/orders/'
const APPROVAL_TYPES = '/core/approval-types'
const APPROVAL_REVIEWRS = '/employee/approval-request-reviewers'
const DEAL_LIST_URL = '/crm/deals'
const DEAL_NOTE_LIST_URL = '/crm/deal/'
const PROSPECT_LIST_URL = 'crm/prospects'
const PROSPECT_EXPORT = 'crm/prospects/export'
const PROSPECT_NOTE_LIST_URL = '/crm/prospect/'
const ACTIVITY_NOTE_LIST_URL = '/sales/orders/task/'
const QUOTE_NOTE_LIST_URL = '/sales/quote/'
const QUOTE_LIST_URL = '/sales/quotes'
const PERMISSSION_LIST_URL = '/core/permissions'
const SERVICE_LIST_URL = 'service/'
const SERVICE_ACTIVITIES_LIST_URL = 'core/check-list'
const INVOICE_LIST_URL = 'invoices'
const COMPANY_EXPORT = 'customer/companies/export/'
const COMPANY_BULK_ASSIGN_CRE = 'customer/companies/bulk-assign-cre/'
const BUSINESS_SECTOR = 'core/business-sectors'
const CONSTITUTION_CATEGORY = 'core/constitution-categories'
const BUSINESS_CATEGORY = 'core/business-industries'
const PAYMENT_LIST = 'sales/payments/'
const LEAD_SOURCE = 'core/lead-source'
const STATE_LIST = 'core/country-state-list'
const EXPORT_CONTACT = 'customer/export/'
const CONTACT_BULK_ASSIGN_CRE = 'customer/bulk-assign-cre/'
const ORDER_EXPORT = 'sales/orders/export'
const ORDER_BULK_ASSIGN_CRE = 'sales/orders/bulk-assign-cre'
const DEAL_EXPORT = 'crm/deals/export'
const DEAL_BULK_ASSIGN_CRE = 'crm/deals/bulk-assign-cre'
const INVOICE_EPORT = 'invoices/export'
const ACTIVITY_LIST = 'core/check-list'
const QUOTE_EXPORT = '/sales/quotes/export'
const QUOTE_BULK_ASSIGN_CRE = '/sales/quotes/bulk-assign-cre'
const COLLECTIBLES = 'core/collectables'
const DELIVERABLES = 'core/deliverables'
const STAKEHOLDER = 'stakeholder'

const ORDER_SERVICE_EXPORT = 'sales/orders/order-services/export/'
const SERVICE_ALLOCATED = 'sales/orders/service-allocation/'
const SERVICE_UN_ALLOCATE = 'sales/orders/service-unallocation'

const PAYMENT_EXPORT = '/sales/payments/export'
const MASTER_DATA = 'core/master-data'
const ORDER_STATUS_ORDER = 'core/order-statuses?type=order'
const SERVICE_CATEGORY = 'core/service-categories?view=all'
const SERVICE_CATEGORY_LIST = 'core/service-categories'
const SERVICE_STATUS_CATEGORY_LIST = 'core/service-status-category'
const ALL_SERVICE = 'service/?view=all'
const ORDER_STATUS = 'core/order-statuses'
const QUOTE_STATUS = 'core/quote-statuses'
const SERVICE_STATUS = 'core/order-service-status'
const PAYMENT_STATUS = 'core/payment-statuses'
const PAYMENT_VERIFICATION_STATUS = 'core/payment-verification-status'
const PAYMENT_TYPES = 'core/payment-types'
const CONTACT_CREATE = 'customer/basic-info/'
const CONTACT_MULTIPLE_DETAILS = 'customer/merge-contact-details'
const ACCOUNT_CREATE = 'customer/company/business-basic-info/'
const ACCOUNT_MULTIPLE_DETAILS = 'customer/company/merge-company-details'
const ACCOUNT_MERGE_SUBMIT = 'customer/company/'
const ACCOUNT_AGENT = 'customer/account/'
const ACCOUNT_LINK = 'customer/company/'
const ACCOUNT_CONTACT = 'customer/account'
const ACCOUNT_CONTACT_LINK = '/customer/account/'
const LEAD_LIST_URL = 'crm/leads'
const UNALLOTTED_LEAD_LIST_URL = 'crm/unallotted_leads'
const LEAD_EXPORT = 'crm/leads/export'
const LEAD_BULK_ASSIGN_CRE = 'crm/leads/bulk-assign-cre'
const LEAD_CREATE = 'crm/leads'
const LEAD_INFO_DUPLICATES = 'crm/possible-lead-duplicates'
const DEAL_DUPLICATES = 'crm/possible-deal-duplicates'
const LIS_LEAD_DUPLICATES = 'crm/'
const LEAD_STATUS_LIST_URL = 'crm/lead/'
const ACTIVITY_STATUS_LIST = 'core/task-statuses'
const LEAD_MERGE_DETAILS = 'crm/lead/merge-lead-details'

const REPORT_CRE_PERFORMANCE_SUMMARY = 'employee/reports/performance-summary'
const REPORT_TEAM_PERFORMANCE_SUMMARY =
  'employee/reports/team-performance-summary'
const REPORT_CRE_SERVICE_STATUS = 'employee/reports/executive-status-report'

const DEAL_MERGE_DETAILS = 'crm/deal/merge-deal-details'
const DEAL_DETAILS_NOTES = 'crm/deal/'
const PROSPECT_DETAILS_NOTES = 'crm/prospect/'
const STAKEHOLDER_LIST_URL = 'stakeholder'
const LEAD_FILES_ATTACHMENTS = 'crm/attachment'
const LEAD_DOCUMENT_TYPE = 'crm/attachment-document-types'
const EVENTS_URL = 'crm/event-logs'
const SERVICE_DETAIL_NOTES_URL = 'sales/orders/'
const SERVICE_DETAIL_URL = 'sales/orders/order-services'
const EVENT_TYPE = 'crm/deal/event-type'
const TASK_URL = 'crm/employee/tasks'
const TASK_CATEGORY = 'crm/deal/employee/tasks/category'
const TASK_STATUS = 'crm/deal/employee/tasks-status'
const ACTIVTY_LIST_URL = 'sales/orders/all-tasks'
const ACTIVTY_EXPORT_URL = 'sales/orders/all-tasks/export'
const ACTIVTY_LIST_CRE = 'sales/orders/'
const TASK_DELIVERABLES =
  'sales/orders/task-deliverables?order_item_task_employee_id='
const TASK_COLLECTABLES =
  'sales/orders/task-collectables?order_item_task_employee_id='
const SERVICE_STATUS_URL = 'service/service_statuses?service_id='
const PAYMENT = 'sales/payments'
const EVENT_LOG = 'crm/eventlog'
const FRANCHISEE = 'franchisee/franchisees/'
const POINTOFSUPPLY = 'core/place-of-supply'
const DEPARTMENTS = 'core/departments'
const NOTIFICATION_ENTITIES = 'notifications/entity-type'
const DESIGNATION = 'core/designations'
const PERMISSIONS = 'core/permissions'
const VENDOR_PERMISSIONS = 'core/vendor-permissions'
const FRANCHISEE_DETAILS = 'franchisee/'
const HEALTH_CHECK = 'core/health_check'
const FRANCHISEE_LIST = 'franchisee/list-employee-franchisee'
const TABLE_PREFRENCES = 'employee/preferences/'
const PRIORITY = 'core/task-priorities'
const SERVICE_PRIORITY = 'core/service-priorities'
const LEAD_QUALITY = 'core/lead-quality'
const NOTIFICATION_LIST = 'notifications/web'
const NOTIFICATION_CLEAR = 'notifications/web/clear_all'
const VENDORS = 'vendors/'
const SUPPLIER = 'core/suppliers'
const SUPPLIER_CATEGORIES = 'core/supplier_categories'
const PAYOUTS = 'sales/payments/payouts'
const PAYOUT_EXPORT = 'sales/payments/vendor_payouts/export'
const PAYMENT_OUT_METHODS = 'core/paymentout-method'
const PAYMENT_OUT_LIST = 'core/paymentout-type'
const PAYMENT_OUT = 'sales/payments/payouts'
const UNALLOTTED_CONTACT_LIST = 'customer/unallotted-contacts/'
const ENQUIRIES = 'support/contact-enquiries'
const ENQUIRY_PRIORITY = 'support/priority'
const ENQUIRY_STATUS = 'support/status'
const SUPPORT = 'support'
const ENQUIRY_TYPE = 'core/enquiry-types'
const apiUrl: { [key: string]: string } = {
  LOGIN_URL,
  PAYMENT_OUT_METHODS,
  PAYMENT_OUT,
  PAYMENT_OUT_LIST,
  MY_TASKS_LIST_URL,
  ACCOUNT_LIST_URL,
  CONTACT_LIST_URL,
  EMPLOYEE_LIST_URL,
  EMPLOYEE_EXPORT,
  TEAMS_LIST_URL,
  TEAM,
  ORDER_SERVICES_URL,
  ORDER_LIST_URL,
  ACTIVITY_NOTE_LIST_URL,
  DEAL_LIST_URL,
  PROSPECT_LIST_URL,
  PROSPECT_EXPORT,
  ACTIVTY_LIST_URL,
  ACTIVTY_EXPORT_URL,
  ACTIVTY_LIST_CRE,
  PERMISSSION_LIST_URL,
  ROLE_LIST_URL,
  USER_URL,
  STAKEHOLDER_LIST_URL,
  QUOTE_STATUS,
  MY_APPROVALS_URL,
  APPROVAL_REVIEWRS,
  MY_APPROVALS_URL_EXCEPT_PAYMENT,
  SERVICE_LIST_URL,
  SERVICE_ACTIVITIES_LIST_URL,
  INVOICE_LIST_URL,
  COMPANY_EXPORT,
  COMPANY_BULK_ASSIGN_CRE,
  BUSINESS_SECTOR,
  CONSTITUTION_CATEGORY,
  BUSINESS_CATEGORY,
  PAYMENT_LIST,
  LEAD_SOURCE,
  STATE_LIST,
  MASTER_DATA,
  EXPORT_CONTACT,
  CONTACT_BULK_ASSIGN_CRE,
  ORDER_SERVICE_EXPORT,
  ORDER_EXPORT,
  ORDER_BULK_ASSIGN_CRE,
  NOTIFICATION_ENTITIES,
  DEAL_EXPORT,
  DEAL_BULK_ASSIGN_CRE,
  PAYMENT_EXPORT,
  ORDER_STATUS,
  SERVICE_CATEGORY,
  ALL_SERVICE,
  INVOICE_EPORT,
  QUOTE_EXPORT,
  QUOTE_BULK_ASSIGN_CRE,
  SERVICE_STATUS,
  ORDER_STATUS_ORDER,
  PAYMENT_STATUS,
  PAYMENT_VERIFICATION_STATUS,
  PAYMENT_TYPES,
  CONTACT_CREATE,
  DEAL_NOTE_LIST_URL,
  PROSPECT_NOTE_LIST_URL,
  QUOTE_NOTE_LIST_URL,
  CONTACT_MULTIPLE_DETAILS,
  ACCOUNT_CREATE,
  LEAD_LIST_URL,
  LEAD_EXPORT,
  LEAD_BULK_ASSIGN_CRE,
  LEAD_CREATE,
  LEAD_INFO_DUPLICATES,
  LEAD_STATUS_LIST_URL,
  LIS_LEAD_DUPLICATES,
  ACCOUNT_MULTIPLE_DETAILS,
  ACCOUNT_MERGE_SUBMIT,
  ACCOUNT_AGENT,
  ACCOUNT_LINK,
  LEAD_MERGE_DETAILS,
  EXPORT_LIST_URL,
  DEAL_MERGE_DETAILS,
  ACCOUNT_CONTACT,
  ACCOUNT_CONTACT_LINK,
  REPORT_CRE_PERFORMANCE_SUMMARY,
  LEAD_FILES_ATTACHMENTS,
  LEAD_DOCUMENT_TYPE,
  DEAL_DUPLICATES,
  EVENTS_URL,
  EVENT_TYPE,
  DEAL_DETAILS_NOTES,
  PROSPECT_DETAILS_NOTES,
  TASK_URL,
  SERVICE_ALLOCATED,
  SERVICE_UN_ALLOCATE,
  SERVICE_DETAIL_NOTES_URL,
  SERVICE_DETAIL_URL,
  TASK_CATEGORY,
  TASK_STATUS,
  COLLECTIBLES,
  DELIVERABLES,
  STAKEHOLDER,
  REPORT_TEAM_PERFORMANCE_SUMMARY,
  REPORT_CRE_SERVICE_STATUS,
  MY_APPROVALS_LIST_URL,
  SERVICE_PAYMENT_APPROVAL,
  MY_APPROVALS_LIST_EXPORT,
  QUOTE_LIST_URL,
  APPROVAL_TYPES,
  APPROVALS_STATUS_LIST_URL,
  ACTIVITY_STATUS_LIST,
  ACTIVITY_LIST,
  TASK_COLLECTABLES,
  TASK_DELIVERABLES,
  SERVICE_STATUS_URL,
  PAYMENT,
  EVENT_LOG,
  FRANCHISEE,
  POINTOFSUPPLY,
  DEPARTMENTS,
  DESIGNATION,
  PERMISSIONS,
  FRANCHISEE_DETAILS,
  FRANCHISEE_LIST,
  TABLE_PREFRENCES,
  SERVICE_CATEGORY_LIST,
  SERVICE_STATUS_CATEGORY_LIST,
  PRIORITY,
  SERVICE_PRIORITY,
  HEALTH_CHECK,
  NOTIFICATION_LIST,
  NOTIFICATION_CLEAR,
  NOTIFICATION_TYPE,
  LEAD_QUALITY,
  VENDORS,
  VENDOR_PERMISSIONS,
  UNALLOTTED_LEAD_LIST_URL,
  SUPPLIER,
  SUPPLIER_CATEGORIES,
  PAYOUTS,
  PAYOUT_EXPORT,
  UNALLOTTED_CONTACT_LIST,
  ENQUIRIES,
  ENQUIRY_PRIORITY,
  ENQUIRY_STATUS,
  SUPPORT,
  ENQUIRY_TYPE,
}

export default apiUrl
