import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  attachmentsDelete,
  getFilesAttachments,
} from '../../../apis/common.apis'
import { router_config } from '../../../configs/route.config'
import { MODULES } from '../../../configs/scopes.config'
import { useAttachmentFilterStore } from '../../../store/filterSore/attachmentStore'
import { downloadFromServer } from '../../../utilities/commonUtilities'
import { getErrorMessage } from '../../../utilities/parsers'
import { Button, DialogModal } from '../../common'
import Icons from '../../common/icons'
import { useSnackbarManager } from '../../common/snackbar'
import InfoBox from '../alertBox/infoBox'
import { PrimaryFilterComponent } from '../filterComponents/primaryFilter'
import { getColumns } from './coloumns'
import CreateFilesAndAttachments from './create'
import { GetFilterDetails } from './filterProperties'

type Props = {
  model_name: string
  handleCallback?: () => void
  disabled?: boolean
  checkModulePermission?: (key: string, sub: string) => boolean
  fromData?: any
}
export default function FileAttachments({
  model_name,
  handleCallback,
  disabled = false,
  checkModulePermission,
  fromData,
}: Props) {
  const [showDetail, setShowDetail] = useState(true)
  const [createOpen, setCreateOpen] = useState(false)
  const params = useParams()
  const [data, setData] = useState<any>()
  const [isLoading, setIsLaoding] = useState(false)
  const { pageParams, setPageParams, setAdvanceFilter, advanceFilter } =
    useAttachmentFilterStore()
  const { page, page_size, search, ordering, filters, filterProps } = pageParams
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState<string>('')
  const { enqueueSnackbar } = useSnackbarManager()
  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    model_id: params?.id,
    model_name: model_name,
    ...filters,
    mode: filters?.mode ? (filters?.mode as string) : undefined,
  }

  const filterDetails = GetFilterDetails({
    model_name: model_name,
    paramsId: params?.id as string,
    mode: searchParams?.mode,
  })

  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams = useAttachmentFilterStore.getState()?.pageParams || {}
    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()

    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
  }

  useEffect(() => {
    if (
      filterProps.created_to_date ||
      (filterProps.created_to_date === null &&
        filterProps.created_from_date === null)
    ) {
      handleCreatedDatepicker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleCreatedDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        created_from_date: filterProps?.created_from_date
          ? moment(filterProps?.created_from_date).format('DD-MM-YYYY')
          : '',
        created_to_date: filterProps?.created_to_date
          ? moment(filterProps.created_to_date).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleFilesAttachments = async () => {
    setIsLaoding(true)
    try {
      const { mode: selectedMode, ...rest }: any = searchParams
      const { data } = await getFilesAttachments({
        ...rest,
        collectable_id:
          selectedMode === '1'
            ? ((rest?.collectable_id as any) ?? undefined)
            : undefined,
        deliverable_id:
          selectedMode === '2'
            ? ((rest?.deliverable_id as any) ?? undefined)
            : undefined,
        type:
          selectedMode === '1'
            ? 'collectable'
            : selectedMode === '2'
              ? 'deliverable'
              : undefined,
      })
      setData(data)
      setIsLaoding(false)
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.response?.data?.error), {
        variant: 'error',
      })
      setIsLaoding(false)
    }
  }

  useEffect(() => {
    handleFilesAttachments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, page, page_size, search, ordering, filters])

  const handleAction = () => {
    setCreateOpen(true)
  }
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDelete = () => {
    attachmentsDelete(item)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        handleFilesAttachments()
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const manageCallback = () => {
    handleCallback?.()
    handleFilesAttachments()
  }

  const handleView = (row: any) => {
    if (row?.attachment) {
      downloadFromServer(row.attachment, row.display_file_name)
    }
  }

  const onViewAction = (r: any, from: string) => {
    if (from == 'linksTo') {
      const linkId = r?.links_to?.id
      switch (r?.links_to?.type) {
        case 'Account':
          return `${router_config.ACCOUNTS.path}/${linkId}/summary`

        case 'Contact':
          return `${router_config.CONTACTS.path}/${linkId}/summary`

        case 'Lead':
          return `${router_config.LEADS.path}/${linkId}/summary`

        case 'Deal':
          return `${router_config.DEALS.path}/${linkId}/summary`

        case 'Quote':
          return `${router_config.QUOTES.path}/${linkId}/summary`

        case 'Order':
          return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${linkId}/summary`

        case 'Service':
          return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${linkId}/summary`

        case 'Activites':
          return `${router_config.ACTIVITIES.path}/${linkId}/summary`

        case 'Prospect':
          return `${router_config.PROSPECTS.path}/${linkId}/summary`
          break
        default:
          return null
      }
    }
  }
  const handleSHowHide = () => {
    return advanceFilter?.filterParams?.find((item) => item.isChecked)
      ? true
      : false
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(search)
  //   const id = urlParams.get('entity')
  //   if (id) {
  //     console.log(id)
  //     onViewAction({ id: id })
  //   }
  // }, [search])

  return (
    <div>
      <DialogModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => setDeleteModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
            }
          />
        }
      />
      <QbsTable
        columns={getColumns(
          { onViewAction: onViewAction },
          model_name,
          checkModulePermission
        )}
        data={data?.results}
        pagination
        toolbar
        isLoading={isLoading}
        // selection
        // onSelect={handleOnSlect}
        search={true}
        searchValue={pageParams.search}
        onSearch={handleSeach}
        asyncSearch
        handleSearchValue={(key?: string) => handleSeach(key)}
        sortColumn=""
        sortType="asc"
        customRowStatus={{
          getIcon: (row) =>
            row?.is_verified ? (
              <Icons name="verify" className=" text-[#336CFC]" />
            ) : row?.has_discrepancy ? (
              <Icons name="raise-deficiency" className=" text-[#C90909]" />
            ) : (
              <></>
            ),
          getToolTip: (row: any) =>
            row?.discrepancy_remarks
              ? row?.discrepancy_remarks
              : row?.is_verified
                ? 'Verified'
                : row?.has_discrepancy
                  ? ' Discrepancy Raised'
                  : '',
        }}
        tableHeaderActions={
          <div className="flex gap-2">
            {handleSHowHide() && (
              <Button
                onClick={() => {
                  setShowDetail(!showDetail)
                }}
                label={showDetail ? 'Hide' : 'Show'}
                className="se"
                primary
              />
            )}
            <Button
              onClick={handleAction}
              label={'Add New'}
              icon="plus"
              disabled={disabled}
              hidden={!checkModulePermission?.('add', MODULES.attachment)}
            />
          </div>
        }
        actionProps={[
          ...(disabled
            ? []
            : [
                {
                  title: 'Delete',
                  action: (rowData: any) => handleDeleteModal(rowData),
                  icon: <Icons name="delete" />,
                  toolTip: 'Delete',
                  hidden: !checkModulePermission?.(
                    'delete',
                    MODULES.attachment
                  ),
                },
                {
                  title: 'Download',
                  action: (rowData: any) => handleView(rowData),
                  icon: <Icons name="download" />,
                  hidden: !checkModulePermission?.(
                    'download',
                    MODULES.attachment
                  ),

                  toolTip: 'Download',
                },
              ]),
        ]}
        paginationProps={{
          onPagination: onChangePage,
          total: data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        primaryFilter={
          <PrimaryFilterComponent
            filterParams={filterDetails}
            handleFilterChange={handleFilterChange}
            filterProps={filterProps}
            advanceFilter={advanceFilter}
            handleFilterDateChange={handleFilterDateChange}
            isPrimary={true}
            setAdvanceFilter={setAdvanceFilter}
          />
        }
        advancefilter={
          showDetail && (
            <PrimaryFilterComponent
              filterParams={filterDetails}
              handleFilterDateChange={handleFilterDateChange}
              handleFilterChange={handleFilterChange}
              filterProps={filterProps}
              isPrimary={false}
              advanceFilter={advanceFilter}
            />
          )
        }
      />
      <CreateFilesAndAttachments
        isDrawerOpen={createOpen}
        model_name={model_name}
        handleClose={() => setCreateOpen(false)}
        handleCallback={manageCallback}
        paramsId={params?.id}
        fromData={fromData}
      />
    </div>
  )
}
