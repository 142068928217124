import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  getbusinsector,
  getConstCat,
  getContacts,
  getCountryState,
} from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { router_config } from '../../../configs/route.config'
import CreateContact from '../../contacts/create/index'
import { checkPermission } from '../../contacts/permission'
import { useCreateAccount } from '../api'
import { getAccountDuplicateData } from '../common/commonUtils'
import { duplicateFields } from '../store'
import { createSchema, CreateSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  handleCallback?: (data: any) => void
  parentPrams?: { id?: string }
  from?: string
}
let updatekey = 1
export default function Create({
  isDrawerOpen,
  handleClose,
  handleCallback,
  subSection,
  parentPrams,
  handleRefresh,
  from,
}: Props) {
  const [saveContinue, setSaveContinue] = useState(false)
  const navigate = useNavigate()
  const [duplicates, setDuplicates] = useState<any>({})
  const [isConatactOpen, setIsContactOpen] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state
  }

  const getConstData = async (value: any) => {
    const { data } = await getConstCat({
      search: value ?? '',
      type: 'dropdown',
    })
    return data?.results?.map((item: any) => ({
      ...item,
      constitution_category_name: item.constitution_category_display_name,
    }))
  }

  const getBustData = async (value: any) => {
    const { data } = await getbusinsector({
      search: value ?? '',
      type: 'dropdown',
    })
    return data?.map((item: any) => ({
      ...item,
      business_sector_name: item.business_sector_display_name,
    }))
  }
  const getContact = async (search?: string, page?: number) => {
    const { data } = await getContacts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    return data?.results?.map((items: any) => ({
      ...items,
      name:
        items.default_contact.first_name +
        ' ' +
        items.default_contact.last_name,
    }))
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false,
    isMobileOrEmail = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
    ...(isMobileOrEmail ? { isMobileOrEmail: true } : {}),
  })
  const handleContactAction = () => {
    setIsContactOpen(true)
  }
  const formBuilderProps = [
    {
      ...textField(
        'business_name',
        'Business Name',
        'Enter Business Name',
        true,
        true
      ),
      toCapitalize: true,
    },
    {
      ...textField('pan_no', 'PAN Number', 'Enter PAN Number', false, true),
      toCapitalize: true,
    },
    textField('gst_no', 'GST Number', 'Enter GST Number', false, true),
    textField('cin', 'CIN', 'Enter CIN Number', false, true),
    {
      name: 'constitutionName',
      label: 'Constitution Category',
      required: false,
      getData: getConstData,
      async: false,
      id: 'constitution',
      descId: 'id',
      initialLoad: true,
      desc: 'constitution_category_name',
      type: 'auto_complete',
      placeholder: 'Enter Constitution Category',
    },
    {
      name: 'sectorName',
      label: 'Sector',
      required: false,
      getData: getBustData,
      async: false,
      id: 'sector',
      descId: 'id',
      initialLoad: true,
      desc: 'business_sector_name',
      type: 'auto_complete',
      placeholder: 'Enter Sector',
    },
    {
      ...textField(
        'mobile',
        'Mobile',
        'Enter Mobile Number',
        false,
        true,
        true
      ),
      type: 'phone',
    },
    textField('email', 'Email', 'Enter Email', false, true, true),
    textField('website', 'Website', 'Enter Website', false),
    {
      name: 'state',
      label: 'State',
      required: true,
      getData: getState,
      async: false,
      id: 'state',
      descId: 'code',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter state',
    },
    textField('city', 'City', 'Enter City', true),

    {
      name: 'contact_name',
      label: 'Primary Contact',
      required: false,
      getData: getContact,
      async: true,
      id: 'customer_id',
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      hidden: from === 'contact',
      placeholder: 'Enter Primary Contact',
      actionLabel: checkPermission('add') ? 'Add New' : '',
      handleAction: handleContactAction,
    },
  ]

  const methods = useForm<CreateSchema>({
    resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = (data: any) => {
    methods.reset({
      pan_no: '',
      website: '',
      description: '',
      pin_code: '',
      phone_number: '',
      city: '',
      stateName: '',
      address_type_name: '',
      address_2: '',
      address_type: '',
      address_1: '',
      email: '',
      state: '',
      email_2: '',
      mobile_2: '',
      business_tier: '',
      lead_source_name: '',
      customer_id: '',
      contact_name: '',
      sub_sector: '',
      sub_sector_name: '',
      lead_source: '',
      business_tier_name: '',
      mobile: '',
      gst_no: '',
      cin: '',
      business_industry_name: '',
      business_name: '',
      constitution: '',
      constitution_category_name: '',
      sector: '',
      business_sector_name: '',
      industry: '',
      sectorName: '',
    })
    updatekey = +1
    if (!saveContinue) {
      if (!subSection) {
        navigate(`${router_config.ACCOUNTS.path}/${data?.id}/summary`)
      }
      handleClearAndClose()
      handleCallback?.(data)
    } else {
      handleCallback?.(data)
    }
  }

  const { mutate, data, isLoading } = useCreateAccount(handleSubmission)
  const onSubmit = (data: CreateSchema, saveAndContinue: boolean) => {
    setIsLoading(true)
    setSaveContinue(saveAndContinue)
    mutate(
      {
        ...data,
        business_name: data?.business_name?.toUpperCase(),
        pan_no: data?.pan_no?.toUpperCase(),
        customer_id: subSection
          ? (parentPrams?.id ?? data?.customer_id)
          : data?.customer_id,
      },
      {
        onSuccess: (res: any) => {
          setTimeout(() => {
            setIsLoading(false)
          }, 500)

          saveAndContinue ? handleRefresh?.() : handleSubmission(res.data)
        },
        onError: () => {
          setIsLoading(false)
        },
      }
    )
  }
  const handleReset = () => {
    methods.reset({})
  }
  useEffect(() => {
    if (subSection) handleCallback?.(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods
  const handleDuplicate = async (fieldName: string, value?: string) => {
    const result = await getAccountDuplicateData(fieldName, value)
    setDuplicates({ [fieldName]: result ?? [] })
  }
  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }
  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
    handleReset()
  }
  const handleContactCallback = useCallback((res: any) => {
    if (res) {
      methods.setValue('customer_id', res?.data?.id)
      methods.setValue(
        'contact_name',
        `${res?.data?.default_contact?.first_name} ${res?.data?.default_contact?.last_name}`,
        {
          shouldValidate: true,
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSaveAndContinue={
        subSection ? undefined : handleSubmit((data) => onSubmit(data, true))
      }
      actionLoader={isLoading ?? loading}
      handleSubmit={handleSubmit((data) => onSubmit(data, false))}
      title="Create New Account"
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder
            data={formBuilderProps}
            edit={true}
            updatekey={`${updatekey}`}
            duplicates={duplicates}
            duplicateFields={duplicateFields}
            clearDuplicates={handleClearDuplicates}
            getDuplicates={handleDuplicate}
          />
        </FormProvider>
        <CreateContact
          isDrawerOpen={isConatactOpen}
          subSection={true}
          handleCallback={handleContactCallback}
          handleClose={() => setIsContactOpen(false)}
        />
      </div>
    </CustomDrawer>
  )
}
