import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext, useParams } from 'react-router-dom'

import FormBuilder from '../../../../../components/app/formBuilder'
import { Button } from '../../../../../components/common'
import Icons from '../../../../../components/common/icons'
import FormFieldView from '../../../../../components/common/inputs/FormFieldView'
import { useEditApplicability } from '../../api'
import { applicabilityschema, ApplicabilitySchema } from './schema'

export function ServiceManagementDetailsApplicability() {
  const [isEditing, setIsEditing] = useState(false)

  const params = useParams()
  // const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    detailsData?: any
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_APPLICABILITY')
  }, [])

  // useEffect(() => {
  //   setEditFlag(isEditing)
  // }, [isEditing])

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const methods = useForm<ApplicabilitySchema>({
    resolver: zodResolver(applicabilityschema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    // if (isEditing) {
    methods.reset({
      applicability: detailsData?.applicability
        ? detailsData?.applicability
        : '',
    })
    // }
  }, [detailsData?.applicability])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: any
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
    spacing: 6,
  })
  const formBuilderProps = [
    {
      ...textField(
        'applicability',
        'Description',
        'Description',
        true,
        detailsData?.applicability
      ),
      type: 'text_editor',
      spacing: 12,
    },
  ]
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} spacing />
          </FormProvider>
        ) : (
          formBuilderProps.map((info) => (
            <div
              key={detailsData?.id}
              className={`col-span-12 md:col-span-${info.spacing || 12}`}
            >
              <FormFieldView
                value={info.value}
                required={info?.required}
                label={info.label}
                type={info?.type}
              />
            </div>
          ))
        )}
      </>
    )
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      applicability: detailsData?.applicability ?? '',
    })
  }
  const handleSubmission = () => {
    setIsEditing(!isEditing)
    handleCallBack?.()
    // methods.reset({
    //   applicability: detailsData?.applicability ?? '',
    // })
  }
  const { mutate, isLoading } = useEditApplicability(handleSubmission)

  const onSubmit = (data: any) => {
    mutate({
      data: { applicability: data?.applicability },
      id: params?.id,
    })
  }
  const { handleSubmit } = methods
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <Icons name="location-pin" />
            <h4 className=" font-bold text-primaryText">Applicability</h4>
          </div>
          <div className="iconBlack cursor-pointer">
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : (
                <Icons onClick={handleEditClick} name="edit" />
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
      </div>
    </div>
  )
}
