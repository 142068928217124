// export default RichTextEditor
type RichTextEditorProps = {
  name: string
  control: Control<any>
  rules?: Record<string, any>
  error?: any
  placeholder?: string
  defaultValue?: string // Serialized Draft.js content (JSON string)
  className?: string
}
import { useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import Quill styles
import { useEffect } from 'react'

const QuillEditor = ({
  control,
  name,
  error,
  defaultValue,
}: RichTextEditorProps) => {
  // Initialize React Hook Form
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const [editorValue, setEditorValue] = useState('')
  useEffect(() => {
    if (defaultValue) {
      setEditorValue(defaultValue)
    }
  }, [])
  return (
    <div>
      {/* Form submission handler */}
      {/* ReactQuill wrapped with Controller */}
      <Controller
        name={name}
        control={control}
        defaultValue={editorValue}
        render={({ field }) => (
          <ReactQuill
            {...field}
            className="min-h-[200px]"
            value={field.value || ''}
            onChange={(value) => {
              setEditorValue(value) // Update local state
              field.onChange(value) // Update React Hook Form state
            }}
            placeholder="Start typing..."
          />
        )}
      />
      {error && error[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(error[name] || error)}
        </div>
      )}
    </div>
  )
}

export default QuillEditor
