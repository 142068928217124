import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const guidelinesschema = z.object({
  guidelines: z
    .string()
    .min(1, { message: 'Required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type GuidelinesSchema = z.infer<typeof guidelinesschema>
