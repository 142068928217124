import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  postFormData,
  updateData,
} from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'
import { QueryParams } from '../../../common/types'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
interface Params {
  [key: string]: string | number | boolean
}

export const createService = (input: any) => {
  return postFormData(`${apiUrl.SERVICE_LIST_URL}`, input)
}
export const updateFormBuilder = (input: any) => {
  return postFormData(
    `${apiUrl.SERVICE_LIST_URL}${input.id}/service-form-layout`,
    input.data
  )
}
export const getFormBuilder = (input: any) => {
  return getData(`${apiUrl.SERVICE_LIST_URL}${input.id}/service-form-layout`)
}
export const useCreateService = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Service Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.SERVICE_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useServiceCategory = (input: QueryParams) => {
  return useQuery(['service-category', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const deleteService = (id: any) => {
  return deleteData(`${apiUrl.SERVICE_LIST_URL}${id}`)
}

export const getServiceDetails = (id: any) => {
  return getData(`${apiUrl.SERVICE_LIST_URL}${id}`)
}

export const editService = (input: { data: any; id: string }) => {
  return updateData(`${apiUrl.SERVICE_LIST_URL}${input.id}`, input.data)
}

export const useEditService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const getServiceCategory = (input: any) => {
  return getData(`${apiUrl.SERVICE_CATEGORY_LIST}${parseQueryParams(input)}`)
}

export const deleteServiceTcDocument = (id: string) => {
  return deleteData(`${apiUrl.SERVICE_LIST_URL}${id}/delete-attachment
`)
}

// Activity

export const {
  getStakeholder,
  getActivityList,
  getCollectibles,
  getDeliverables,
} = {
  getStakeholder: (data: any) =>
    getData(buildUrlWithParams(apiUrl.STAKEHOLDER, data)),
  getActivityList: (params: Params) =>
    getData(buildUrlWithParams(`${apiUrl.ACTIVITY_LIST}`, params)),
  getCollectibles: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.COLLECTIBLES, params)),
  getDeliverables: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.DELIVERABLES, params)),
}

export const getServiceActivityList = (input: any, id: string) => {
  return getData(
    `${apiUrl.SERVICE_LIST_URL}${id}/service-checklist${parseQueryParams(
      input
    )}`
  )
}

// SERVICE_LIST_URL

export const addActivity = (data: {
  input: any
  serviceId: string
  orderId: string
}) => {
  return postData(
    `${apiUrl.SERVICE_LIST_URL}${data?.serviceId}/service-checklist`,
    data?.input
  )
}

export const useAddActivity = (
  handleSubmission: (input: any, serviceId: string, orderId: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addActivity, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.serviceId, res.orderId)
      enqueueSnackbar('Activity Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.error.message[0] ??
            error.response.data.errors ??
            error.response.data.errors[0].message[0]
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const delete_activity = (id?: string) => {
  return deleteData(`${apiUrl.SERVICE_LIST_URL}${id}/service-checklist-delete`)
}

export const getServiceStatus = (input: any) => {
  return getData(`${apiUrl.SERVICE_STATUS}${parseQueryParams(input)}`)
}

export const getServiceStatusCategory = (input: any) => {
  return getData(
    `${apiUrl.SERVICE_STATUS_CATEGORY_LIST}${parseQueryParams(input)}`
  )
}

export const deleteServiceStatus = (id: any) => {
  return deleteData(`${apiUrl.SERVICE_LIST_URL}service_statuses/${id}`)
}

export const addServiceStatus = (data: any) => {
  return postData(`${apiUrl.SERVICE_LIST_URL}service_statuses`, data)
}

export const getServiceStatusList = (input: any) => {
  return getData(
    `${apiUrl.SERVICE_LIST_URL}service_statuses${parseQueryParams(input)}`
  )
}

export const getServiceEligibilityCriteriaList = (id: string, input: any) => {
  return getData(
    `${apiUrl.SERVICE_LIST_URL}${id}/service-prerequisits${parseQueryParams(
      input
    )}`
  )
}

export const addServiceEligibilityCriteria = (id: string, data: any) => {
  return postData(`${apiUrl.SERVICE_LIST_URL}${id}/service-prerequisits`, data)
}

export const deleteEligibilityCriteria = (service_id: string, id: string) => {
  return deleteData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-prerequisits/${id}`
  )
}

export const updateEligibilityCriteria = (
  service_id: string,
  id: string,
  data: any
) => {
  return updateData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-prerequisits/${id}`,
    data
  )
}

export const getServiceDocumentRequiredList = (id: string, input: any) => {
  return getData(
    `${apiUrl.SERVICE_LIST_URL}${id}/service-documents${parseQueryParams(
      input
    )}`
  )
}

export const addServiceDocumentRequired = (id: string, data: any) => {
  return postData(`${apiUrl.SERVICE_LIST_URL}${id}/service-documents`, data)
}

export const deleteDocumentRequired = (service_id: string, id: string) => {
  return deleteData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-documents/${id}`
  )
}

export const updateDocumentRequired = (
  service_id: string,
  id: string,
  data: any
) => {
  return updateData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-documents/${id}`,
    data
  )
}

export const getServiceProcessList = (id: string, input: any) => {
  return getData(
    `${apiUrl.SERVICE_LIST_URL}${id}/service-procedures${parseQueryParams(
      input
    )}`
  )
}

export const addServiceProcess = (id: string, data: any) => {
  return postData(`${apiUrl.SERVICE_LIST_URL}${id}/service-procedures`, data)
}

export const deleteProcess = (service_id: string, id: string) => {
  return deleteData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-procedures/${id}`
  )
}

export const updateProcess = (service_id: string, id: string, data: any) => {
  return updateData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-procedures/${id}`,
    data
  )
}

export const reOrderProcess = (service_id: string, id: string, data: any) => {
  return patchData(
    `${apiUrl.SERVICE_LIST_URL}${service_id}/service-procedures/${id}/update_step`,
    data
  )
}

export const editApplicability = (input: { data: any; id: string }) => {
  return patchData(
    `${apiUrl.SERVICE_LIST_URL}${input.id}/update_applicability`,
    input.data
  )
}

export const useEditApplicability = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editApplicability, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const editGuideLines = (input: { data: any; id: string }) => {
  return patchData(
    `${apiUrl.SERVICE_LIST_URL}${input.id}/update_guidelines`,
    input.data
  )
}

export const useEditGuideLines = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editGuideLines, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
