import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const applicabilityschema = z.object({
  applicability: z
    .string()
    .min(1, { message: 'Required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type ApplicabilitySchema = z.infer<typeof applicabilityschema>
