import moment from 'moment'
import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import {
  getEmployeeList,
  getFullFranchiseeList,
} from '../../../apis/common.apis'
import { TabItemProps } from '../../../common/types'
import { TabContainer } from '../../../components/common'
import DetailHeader from '../../../components/common/DetailTiles'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { router_config } from '../../../configs/route.config'
import { useAppStore } from '../../../store/appStore'
import { handleError } from '../../../utilities/commonUtilities'
import {
  getContactDetails,
  updateContactCre,
  updateContactFranchisee,
} from '../api'

// import { checkPermission } from '../permission'

let updateKey = 1
const UnallottedContactDetails = () => {
  const navigate = useNavigate()
  const [edit] = useState(false)
  const [data, setData] = useState<any>()
  const { setIsLoading } = useAppStore()

  const [accountCount, setAccountCount] = useState(0)
  const [quoteCount, setQuoteCount] = useState(0)

  const params = useParams()
  const tabData = [
    {
      label: router_config.UNALLOTTED_CONTACT_DETAILS_PROFILE.label,
      id: 'UNALLOTTED_CONTACT_DETAILS_PROFILE',
    },
    {
      label: router_config.UNALLOTTED_CONTACT_DETAILS_ACCOUNTS.label,
      id: 'UNALLOTTED_CONTACT_DETAILS_ACCOUNTS',
      hidden: accountCount <= 1,
    },
    {
      label: router_config.UNALLOTTED_CONTACT_DETAILS_QUOTES.label,
      id: 'UNALLOTTED_CONTACT_DETAILS_QUOTES',
      hidden: quoteCount === 0,
    },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'UNALLOTTED_CONTACT_DETAILS_PROFILE'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }
  const getEmployee = async (search = '', page = 1) => {
    const data = await getEmployeeList({ search, page, type: 'dropdown' })
    const drpdownItems = data?.results?.map((item: any) => ({
      label: item.name,
      value: item.name,
      id: item.id,
    }))
    return { data: drpdownItems, hasNext: data?.next ? true : false }
  }
  const [franchisees, setFranchisees] = useState([])

  const getFranchiseeList = async () => {
    const data = await getFullFranchiseeList()

    const result = data?.data.franchisees?.map((item: any) => ({
      // ...item,
      franchisee_display_name:
        item?.franchisee_display_name ?? item?.franchisee_name,
      label: item?.franchisee_display_name ?? item?.franchisee_name,
      value: item?.franchisee_display_name ?? item?.franchisee_name,
      id: item.id,
    }))
    setFranchisees(result)
    return { data: result, hasNext: data?.next ? true : false }
  }

  useEffect(() => {
    getFranchiseeList()
    if (params.id) getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const getData = async () => {
    setIsLoading(true)
    getContactDetails(params.id)
      .then((res) => {
        setData(res.data)

        setAccountCount(res?.data?.no_of_accounts)
        setQuoteCount(res?.data?.tickets)
        setIsLoading(false)
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const updateFranchisee = (id: any) => {
    const details = { franchisee_id: id ?? null }
    updateContactFranchisee(details, params?.id as string)
      .then(() => {
        enqueueSnackbar('Franchisee  assigned successfully', {
          variant: 'success',
        })
        navigate('/unallotted-contacts')
      })

      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const tiledata = [
    {
      id: 1,
      tiles: [
        {
          label: 'Primary CRE',
          value: data?.cre_agent?.name,
          getData: getEmployee,
          id: 1,
        },
        {
          icon: 'phone',
          value: data?.default_contact?.mobile ?? '- -',
          id: 2,
        },
        {
          icon: 'email',
          value: data?.default_contact?.email ?? '- -',
          id: 3,
        },
        {
          icon: 'calendar',
          value: data?.date_of_birth
            ? moment(data?.date_of_birth).format('DD MMM YYYY')
            : '--',
          id: 4,
          optional: true,
        },
      ],
    },
    {
      id: 2,
      tiles: [
        {
          id: 1,
          icon: 'home',
          value: ` ${data?.addresses[0]?.city ?? ''}, ${
            data?.addresses[0]?.state
          } `,
          // handleAction: updateFranchisee,
        },
      ],
    },
    {
      id: 3,
      tiles: [
        {
          label: 'Franchisee',
          value: '- -',
          id: 1,
          slug: 'franchisee',
        },
      ],
    },
  ]
  const chipdata = [
    { id: 1, status: 'Normal' },
    { id: 2, status: 'Normal' },
    { id: 3, status: 'Normal' },
  ]
  const basicData = {
    title: `${data?.default_contact?.first_name ?? '--'} ${
      data?.default_contact?.last_name ?? '--'
    }`,
    subtitle: `${
      data?.primary_company?.business_name
        ? `${data?.primary_company?.business_name}`
        : '--'
    }`,
    count: data?.no_of_accounts > 1 ? data?.no_of_accounts - 1 : '--',
    // count: data?.no_of_accounts > 1 ? data?.no_of_accounts - 1 : ('' ?? '--'),
    subLink: `${router_config.ACCOUNTS.path}/${data?.primary_company?.id}/summary`,
    image: data?.profile_image,
    type: `${data?.created_source}`,
  }

  const handleCallback = () => {
    getData()
  }
  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,
    detailData: data,
    handleParentCallback: handleCallback,
  }
  const { enqueueSnackbar } = useSnackbarManager()
  const updateCRE = (id?: string) => {
    const details = { assigned_cre_id: id ?? null }
    updateContactCre(data?.id as string, details).then(() => {
      enqueueSnackbar('CRE updated successfully', { variant: 'success' })
      getData()
      updateKey = updateKey + 1
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const handleUpdateCre = (id?: string) => {
    updateCRE(id)
  }
  return (
    <div>
      <div>
        <DetailHeader
          tiledata={tiledata}
          chipdata={chipdata}
          unAllocate={true}
          data={basicData}
          setUpdateCREId={handleUpdateCre}
          disabled={true}
          franchiseeList={franchisees}
          handleStatus={updateFranchisee}
        />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>
    </div>
  )
}

export default UnallottedContactDetails
