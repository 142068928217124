import { useAuthStore } from '../store/authStore'
import { MODULES, SCOPES } from './scopes.config'

export const handleViewPermission = (key: string) => {
  return [`view_${key}`, `view_all_${key}`, `view_team_${key}`]
}
export interface RouterMenuProps {
  id: number
  path?: string
  parent_id: null | number
  icon?: string
  breadcrumb_path?: string
  label: string
  key: string
  slug?: string
  permission_slugs: string | string[]
  slugOptions?: string[]
  breadcrumb?: string[]
  isDetails?: boolean
  isExpanded?: boolean
  isSidebarMenu?: boolean
  hasChild?: boolean
  hasDivider?: boolean
  pathOverride?: string
  publicPermission?: boolean
}

// dashboard
const isAllFranchisee = () =>
  useAuthStore.getState().franchisee?.franchisee_name === 'All Franchisee'

const LOGIN: RouterMenuProps = {
  id: 0,
  path: '/login',
  parent_id: null,
  permission_slugs: [],
  label: 'Login',
  key: 'login',
}

const FORGET_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/forget-password',
  parent_id: null,
  permission_slugs: [],
  label: 'Forget Password',
  key: 'forget_password',
}
const RESET_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/reset-password/:token',
  parent_id: null,
  permission_slugs: [],
  label: 'Reset Password',
  key: 'reset_password',
}
const CHANGE_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/change-password',
  parent_id: null,
  permission_slugs: [],
  label: 'Change Password',
  key: 'change_password',
}
// dashboard

const DASHBOARD: RouterMenuProps = {
  id: 1,
  path: '/dashboard',
  parent_id: null,
  icon: 'dashboard-icon',
  label: 'Dashboard',
  key: 'dashboard',
  permission_slugs: [...handleViewPermission(MODULES.dashboard)],
  // breadcrumb: ["DASHBOARD"],
  isSidebarMenu: true,
}

//LEADS

const LEADS: RouterMenuProps = {
  id: 2,
  path: '/leads',
  parent_id: null,
  icon: 'leads-icon',
  label: 'Leads',
  key: 'lead',
  permission_slugs: [...handleViewPermission(MODULES.lead)],
  isSidebarMenu: true,
  slugOptions: [
    'LEAD_DETAILS_PROFILE',
    'LEAD_MERGE',
    'LEAD_DETAILS_POSSIBLE_DUPLICATES',
    'LEAD_DETAILS',
    'LEAD_DETAILS_NOTES',
    'LEAD_DETAILS_EVENT_LOGS',
    'LEAD_FILES_AND_ATTACHMENTS',
    'LEAD_TASKS',
  ],
  hasChild: false,
}
const LEAD_DETAILS: RouterMenuProps = {
  id: 21,
  path: '/leads/:id',
  parent_id: 2,
  isDetails: true,
  icon: 'user',
  label: 'Lead Details',
  key: 'lead-details',
  permission_slugs: [...handleViewPermission(MODULES.lead)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS'],
}
const LEAD_DETAILS_PROFILE: RouterMenuProps = {
  id: 211,
  path: 'summary',
  parent_id: 21,
  icon: 'user',
  label: 'Summary',
  key: 'lead-details-profile',
  permission_slugs: [...handleViewPermission(MODULES.lead)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_DETAILS_PROFILE'],
}
const LEAD_MERGE: RouterMenuProps = {
  id: 212,
  path: '/leads/merge',
  parent_id: null,
  icon: 'merge',
  label: 'Merge',
  key: 'lead-merge',
  permission_slugs: [MODULES.merge_lead],
}
const LEAD_DETAILS_MERGE: RouterMenuProps = {
  id: 219,
  path: '/leads/:id/merge',
  parent_id: null,
  icon: 'merge',
  label: 'Merge',
  key: 'lead-details-merge',
  permission_slugs: [MODULES.merge_lead],
}
const LEAD_DETAILS_NOTES: RouterMenuProps = {
  id: 215,
  path: 'notes',
  parent_id: 21,
  icon: 'user',
  label: 'Notes',
  key: 'lead-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.lead_note)],

  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_DETAILS_NOTES'],
}
const LEAD_DETAILS_POSSIBLE_DUPLICATES: RouterMenuProps = {
  id: 213,
  path: 'possible-duplicates',
  parent_id: 21,
  icon: 'user',
  label: 'Possible Duplicates',
  key: 'lead-details-duplicates',
  permission_slugs: [...handleViewPermission(MODULES.lead_possibleduplicate)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_DETAILS_POSSIBLE_DUPLICATES'],
}

const LEAD_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 214,
  path: 'event-logs',
  parent_id: 21,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.lead_eventlog)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_DETAILS_EVENT_LOGS'],
}

const LEAD_FILES_AND_ATTACHMENTS: RouterMenuProps = {
  id: 216,
  path: 'attachments',
  parent_id: 21,
  icon: 'user',
  label: 'Files',
  key: 'lead-attachments',
  permission_slugs: [...handleViewPermission(MODULES.lead_attachment)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_FILES_AND_ATTACHMENTS'],
}
const LEAD_TASKS: RouterMenuProps = {
  id: 216,
  path: 'tasks',
  parent_id: 21,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.lead_task)],
  breadcrumb: ['LEADS', 'LEAD_DETAILS', 'LEAD_TASKS'],
}
// employee

// const EMPLOYEES: RouterMenuProps = {
//   id: 2,
//   path: "/employees",
//   parent_id: null,
//   icon: 'BsPersonFill',
//   label: "Employees",
//   key: "employees",
//   permission_slugs: [SCOPES.employee],
//   breadcrumb: ["EMPLOYEES"],
//   isSidebarMenu: true,
// };

//Accounts

const ACCOUNTS: RouterMenuProps = {
  id: 3,
  path: '/account',
  parent_id: null,
  icon: 'building-icon',
  label: 'Accounts',
  key: 'customercompany',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  slugOptions: [
    'ACCOUNT_DETAILS_BASIC_INFO',
    'ACCOUNT_DETAILS_CONTACTS',
    'ACCOUNT_DETAILS',
    'ACCOUNT_DETAILS_AGENTS',
    'ACCOUNT_DETAILS_ADDRESSES',
    'ACCOUNT_POSSIBLE_DUPLICATES',
    'ACCOUNT_DETAILS_ORDERS',
    'ACCOUNT_DETAILS_EVENT_LOGS',
    'ACCOUNT_FILES_AND_ATTACHMENTS',
    'ACCOUNT_TASKS',
    'ACCOUNT_DETAILS_NOTES',
  ],
  isSidebarMenu: true,
}

const ACCOUNT_DETAILS: RouterMenuProps = {
  id: 31,
  path: '/account/:id/',
  parent_id: 3,
  isDetails: true,
  icon: 'building-icon',
  label: 'Account Details',
  key: 'account-details',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS'],
}
const ACCOUNT_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 3001,
  path: 'event-logs',
  parent_id: 31,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.account_eventlog)],

  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_EVENT_LOGS'],
}
const ACCOUNT_DETAILS_NOTES: RouterMenuProps = {
  id: 3089,
  path: 'notes',
  parent_id: 3,
  icon: 'user',
  label: 'Notes',
  key: 'account-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.account_note)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_NOTES'],
}
const ACCOUNT_FILES_AND_ATTACHMENTS: RouterMenuProps = {
  id: 3002,
  path: 'attachments',
  parent_id: 31,
  icon: 'user',
  label: 'Files',
  key: 'lead-attachments',
  permission_slugs: [...handleViewPermission(MODULES.account_attachment)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_FILES_AND_ATTACHMENTS'],
}
const ACCOUNT_TASKS: RouterMenuProps = {
  id: 3003,
  path: 'tasks',
  parent_id: 31,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.account_task)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_TASKS'],
}
const ACCOUNT_DETAILS_CONTACTS: RouterMenuProps = {
  id: 314,
  path: 'contacts',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Contacts',
  key: 'account-details-contacts',
  permission_slugs: [...handleViewPermission(MODULES.account_contact)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_CONTACTS'],
}
const ACCOUNT_DETAILS_BASIC_INFO: RouterMenuProps = {
  id: 311,
  path: 'summary',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Summary',
  key: 'account-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_BASIC_INFO'],
}
const ACCOUNT_DETAILS_AGENTS: RouterMenuProps = {
  id: 312,
  path: 'agents',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Agents',
  key: 'account-details-agents',
  permission_slugs: [...handleViewPermission(MODULES.account_agent)],

  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_AGENTS'],
}
const ACCOUNT_DETAILS_ORDERS: RouterMenuProps = {
  id: 316,
  path: 'orders',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Orders',
  key: 'account-details-orders',
  permission_slugs: [...handleViewPermission(MODULES.account_order)],

  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_ORDERS'],
}
const ACCOUNT_DETAILS_ADDRESSES: RouterMenuProps = {
  id: 313,
  path: 'address',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Addresses',
  key: 'account-details-address',
  permission_slugs: [
    SCOPES.view_customercompany,
    SCOPES.change_customercompany,
  ],
  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_ADDRESSES'],
}
const ACCOUNT_MERGE: RouterMenuProps = {
  id: 420,
  path: '/account/merge',
  parent_id: 4,
  icon: 'merge',
  label: 'Merge',
  key: 'account-merge',
  permission_slugs: [MODULES.merge_account],
}
const ACCOUNT_DETAILS_MERGE: RouterMenuProps = {
  id: 421,
  path: '/account/:id/merge',
  parent_id: null,
  icon: 'merge',
  label: 'Merge',
  key: 'account-merge',
  permission_slugs: [MODULES.merge_account],
}
const ACCOUNT_POSSIBLE_DUPLICATES: RouterMenuProps = {
  id: 315,
  path: 'possible-duplicates',
  parent_id: 41,
  icon: 'user',
  label: 'Possible Duplicates',
  key: 'account-details-duplicates',
  permission_slugs: [
    ...handleViewPermission(MODULES.account_possibleduplicate),
  ],

  breadcrumb: ['ACCOUNTS', 'ACCOUNT_DETAILS', 'ACCOUNT_POSSIBLE_DUPLICATES'],
}
//Contacts

const CONTACTS: RouterMenuProps = {
  id: 4,
  path: '/contacts',
  parent_id: null,
  icon: 'user',
  label: 'Contacts',
  key: 'customer',
  permission_slugs: [...handleViewPermission(MODULES.contact)],
  isSidebarMenu: true,
  slugOptions: [
    'CONTACT_DETAILS_SUMMARY',
    'CONTACT_DETAILS_ACCOUNTS',
    'CONTACT_MERGE',
    'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
    'CONTACT_DETAILS_EVENT_LOGS',
    'CONTACT_FILES_AND_ATTACHMENTS',
    'CONTACT_TASKS',
    'CONTACT_DETAILS_NOTES',
  ],
  hasChild: false,
  hasDivider: false,
}

const UNALLOTTED_CONTACTS: RouterMenuProps = {
  id: 22,
  path: '/unallotted-contacts',
  parent_id: null,
  icon: 'user',
  label: 'Unallotted Contacts',
  key: 'customer',
  permission_slugs: [],
  isSidebarMenu: true,
  slugOptions: [],
  hasChild: false,
  hasDivider: true,
}

const UNALLOTTED_CONTACT_DETAILS: RouterMenuProps = {
  id: 221,
  path: '/unallotted-contacts/:id/',
  parent_id: 22,
  isDetails: true,
  icon: 'user',
  label: 'Contact Details',
  key: 'unallotted-contact-details',
  permission_slugs: [],
  breadcrumb: ['UNALLOTTED_CONTACTS', 'UNALLOTTED_CONTACT_DETAILS'],
}

const UNALLOTTED_CONTACT_DETAILS_PROFILE: RouterMenuProps = {
  id: 222,
  path: 'summary',
  parent_id: 22,
  icon: 'user',
  label: 'Summary',
  key: 'unallotted-contact-details-profile',
  permission_slugs: [],
  breadcrumb: [
    'UNALLOTTED_CONTACTS',
    'UNALLOTTED_CONTACT_DETAILS',
    'UNALLOTTED_CONTACT_DETAILS_PROFILE',
  ],
}

const UNALLOTTED_CONTACT_DETAILS_ACCOUNTS: RouterMenuProps = {
  id: 222,
  path: 'accounts',
  parent_id: 22,
  icon: 'user',
  label: 'Accounts',
  key: 'unallotted-contact-details-accounts',
  permission_slugs: [],
  breadcrumb: [
    'UNALLOTTED_CONTACTS',
    'UNALLOTTED_CONTACT_DETAILS',
    'UNALLOTTED_CONTACT_DETAILS_ACCOUNTS',
  ],
}

const UNALLOTTED_CONTACT_DETAILS_QUOTES: RouterMenuProps = {
  id: 223,
  path: 'quotes',
  parent_id: 22,
  icon: 'user',
  label: 'Quotes',
  key: 'unallotted-contact-details-quotes',
  permission_slugs: [],
  breadcrumb: [
    'UNALLOTTED_CONTACTS',
    'UNALLOTTED_CONTACT_DETAILS',
    'UNALLOTTED_CONTACT_DETAILS_QUOTES',
  ],
}

const CONTACT_MERGE: RouterMenuProps = {
  id: 420,
  path: '/contacts/merge',
  parent_id: 4,
  icon: 'merge',
  label: 'Merge',
  key: 'contact-merge',
  permission_slugs: [MODULES.merge_contact],
}
const CONTACT_DETAILS_MERGE: RouterMenuProps = {
  id: 421,
  path: '/contacts/:id/merge',
  parent_id: null,
  icon: 'merge',
  label: 'Merge',
  key: 'contact-merge',
  permission_slugs: [MODULES.merge_contact],
}

const CONTACT_DETAILS: RouterMenuProps = {
  id: 41,
  path: '/contacts/:id/',
  parent_id: 4,
  isDetails: true,
  icon: 'user',
  label: 'Contact Details',
  key: 'contact-details',
  permission_slugs: [...handleViewPermission(MODULES.contact)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS'],
}
const CONTACT_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 5001,
  path: 'event-logs',
  parent_id: 4,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.contact_eventlog)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_DETAILS_EVENT_LOGS'],
}
const CONTACT_DETAILS_NOTES: RouterMenuProps = {
  id: 215,
  path: 'notes',
  parent_id: 21,
  icon: 'user',
  label: 'Notes',
  key: 'contact-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.contact_note)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_DETAILS_NOTES'],
}

const CONTACT_FILES_AND_ATTACHMENTS: RouterMenuProps = {
  id: 4002,
  path: 'attachments',
  parent_id: 41,
  icon: 'user',
  label: 'Files',
  key: 'lead-attachments',
  permission_slugs: [...handleViewPermission(MODULES.contact_attachment)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_FILES_AND_ATTACHMENTS'],
}
const CONTACT_TASKS: RouterMenuProps = {
  id: 4003,
  path: 'tasks',
  parent_id: 41,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.contact_task)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_TASKS'],
}

const CONTACT_DETAILS_SUMMARY: RouterMenuProps = {
  id: 411,
  path: 'summary',
  parent_id: 41,
  icon: 'user',
  label: 'Summary',
  key: 'contact-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.contact)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_DETAILS_SUMMARY'],
}
const CONTACT_DETAILS_ACCOUNTS: RouterMenuProps = {
  id: 412,
  path: 'accounts',
  parent_id: 41,
  icon: 'user',
  label: 'Accounts',
  key: 'contact-details-accounts',
  permission_slugs: [...handleViewPermission(MODULES.contact_account)],
  breadcrumb: ['CONTACTS', 'CONTACT_DETAILS', 'CONTACT_DETAILS_ACCOUNTS'],
}

const CONTACT_DETAILS_POSSIBLE_DUPLICATES: RouterMenuProps = {
  id: 413,
  path: 'possible-duplicates',
  parent_id: 41,
  icon: 'user',
  label: 'Possible Duplicates',
  key: 'contact-details-duplicates',
  permission_slugs: [
    ...handleViewPermission(MODULES.contact_possibleduplicate),
  ],
  breadcrumb: [
    'CONTACTS',
    'CONTACT_DETAILS',
    'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
  ],
}

//My Tasks

const MY_TASKS: RouterMenuProps = {
  id: 5,
  path: '/my-tasks',
  parent_id: null,
  icon: 'mytasks-icon',
  label: 'My Tasks',
  key: 'my-tasks',
  permission_slugs: [],
  // breadcrumb: ['MY_TASKS'],
  publicPermission: true,
  isSidebarMenu: true,
  hasChild: false,
}
const MY_TASKS_VIEW: RouterMenuProps = {
  id: 5,
  path: '/my-tasks/:id',
  parent_id: null,
  icon: 'mytasks-icon',
  label: 'My Tasks',
  key: 'my-tasks',
  permission_slugs: [],
  // breadcrumb: ['MY_TASKS'],
  publicPermission: true,
  isSidebarMenu: false,
  hasChild: false,
}
//My Approvals

const MY_APPROVALS: RouterMenuProps = {
  id: 6,
  path: '/my-approvals',
  parent_id: null,
  icon: 'myaprrovals-icon',
  label: 'My Approvals',
  key: 'my-approvals',
  permission_slugs: [],
  publicPermission: true,

  // breadcrumb: ['MY_APPROVALS'],
  isSidebarMenu: true,
  hasChild: false,
}
const MY_APPROVAL_DETAILS: RouterMenuProps = {
  id: 6,
  path: '/my-approvals/:id',
  parent_id: null,
  icon: 'myaprrovals-icon',
  label: 'My Approvals',
  key: 'my-approvals',
  permission_slugs: [],
  publicPermission: true,

  // breadcrumb: ['MY_APPROVALS'],
  isSidebarMenu: false,
  hasChild: false,
}
// Invoices

const INVOICES: RouterMenuProps = {
  id: 11,
  path: '/invoices',
  parent_id: null,
  icon: 'receipt-icon',
  label: 'Invoices',
  key: 'invoice',
  permission_slugs: [...handleViewPermission(MODULES.invoice)],
  // breadcrumb: ["MY_APPROVALS"],
  isSidebarMenu: true,
  hasChild: false,
  slugOptions: ['INVOICES', 'INVOICES_DETAILS'],
  hasDivider: true,
}
const INVOICES_DETAILS: RouterMenuProps = {
  id: 14,
  path: '/invoices/:id/invoices-details',
  parent_id: null,
  icon: 'receipt-icon',
  label: 'Invoice Details',
  key: 'invoice-details',
  permission_slugs: [MODULES.document_invoice],
  slugOptions: ['INVOICES_DETAILS'],

  hasChild: false,
}

// ENQUIRIES

const ENQUIRIES: RouterMenuProps = {
  id: 16,
  path: '/enquiries',
  parent_id: null,
  icon: 'myaprrovals-icon',
  label: 'Enquiries',
  key: 'support',
  permission_slugs: [...handleViewPermission(MODULES.enquiry)],
  isSidebarMenu: true,
  hasChild: false,
  slugOptions: [
    'ENQUIRIES',
    'ENQUIRIES_DETAILS',
    'ENQUIRIES_DETAILS_SUMMARY',
    'ENQUIRIES_DETAILS_REPLIES',
  ],
  hasDivider: true,
}
const ENQUIRIES_DETAILS: RouterMenuProps = {
  id: 166,
  path: '/enquiries/:id/',
  parent_id: 16,
  isDetails: true,
  icon: 'myaprrovals-icon',
  label: 'Enquiries Details',
  key: 'enquiries-details',
  permission_slugs: [...handleViewPermission(MODULES.enquiry)],
  breadcrumb: ['ENQUIRIES', 'ENQUIRIES_DETAILS'],
}
const ENQUIRIES_DETAILS_SUMMARY: RouterMenuProps = {
  id: 166,
  path: 'summary',
  parent_id: 16,
  isDetails: true,
  icon: 'myaprrovals-icon',
  label: 'Summary ',
  key: 'enquiries-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.enquiry)],
  breadcrumb: ['ENQUIRIES', 'ENQUIRIES_DETAILS', 'ENQUIRIES_DETAILS_SUMMARY'],
}
const ENQUIRIES_DETAILS_REPLIES: RouterMenuProps = {
  id: 166,
  path: 'replies',
  parent_id: 16,
  isDetails: true,
  icon: 'myaprrovals-icon',
  label: 'Replies ',
  key: 'enquiries-details-replies',
  permission_slugs: [...handleViewPermission(MODULES.enquiry)],
  breadcrumb: ['ENQUIRIES', 'ENQUIRIES_DETAILS', 'ENQUIRIES_DETAILS_REPLIES'],
}

// PAYMENTS

const PAYMENTS: RouterMenuProps = {
  id: 13,
  path: '/payments',
  parent_id: null,
  icon: 'payment-icon',
  label: 'Payments',
  key: 'payment',
  permission_slugs: [...handleViewPermission(MODULES.payment)],
  // breadcrumb: ["MY_APPROVALS"],
  isSidebarMenu: true,
  hasChild: false,
  hasDivider: false,
}
const PAYOUTS: RouterMenuProps = {
  id: 20,
  path: '/payouts',
  parent_id: null,
  icon: 'payment-icon',
  label: 'Payouts',
  key: 'paymentout',
  permission_slugs: [...handleViewPermission(MODULES.paymentout)],
  // breadcrumb: ["MY_APPROVALS"],
  isSidebarMenu: true,
  hasChild: false,
  hasDivider: false,
}

//My Payment Approvals

const MY_PAYMENT_APPROVALS: RouterMenuProps = {
  id: 7,
  path: '/my-payment-approvals',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'My Payment Approvals',
  key: 'my-payment-approvals',
  permission_slugs: [],
  publicPermission: true,
  // breadcrumb: ['MY_PAYMENT_APPROVALS'],
  isSidebarMenu: true,
  hasChild: false,
  hasDivider: true,
}
const MY_PAYMENT_APPROVAL_DETAILS: RouterMenuProps = {
  id: 7,
  path: '/my-payment-approvals/:id',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'My Payment Approvals',
  key: 'my-payment-approvals',
  permission_slugs: [],
  publicPermission: true,
  // breadcrumb: ['MY_PAYMENT_APPROVALS'],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: true,
}
//REPORTS

const REPORTS: RouterMenuProps = {
  id: 10,
  path: '/reports',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'Reports',
  key: 'report',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  // breadcrumb: ["MY_PAYMENT_APPROVALS"],
  isSidebarMenu: true,
  hasChild: false,
}
const REPORT_CRE_PERFORMANCE_SUMMARY: RouterMenuProps = {
  id: 101,
  path: '/reports/cre-performace-summary',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'CRE Performance Reports',
  key: 'cre-perf-reports',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  // breadcrumb: ["MY_PAYMENT_APPROVALS"],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}
const REPORT_TEAM_PERFORMANCE_SUMMARY: RouterMenuProps = {
  id: 102,
  path: '/reports/team-performace-summary',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'Team Performance Reports',
  key: 'team-perf-reports',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  // breadcrumb: ["MY_PAYMENT_APPROVALS"],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}
const REPORT_CRE_SERVICE_STATUS: RouterMenuProps = {
  id: 101,
  path: '/reports/cre-service-status',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'CRE Service Status Reports',
  key: 'cre-service-status',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  // breadcrumb: ["MY_PAYMENT_APPROVALS"],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}

//Service Orders

// const SERVICE_AND_ORDERS: RouterMenuProps = {
//   id: 8,
//   path: '/service-orders',
//   parent_id: null,
//   isSidebarMenu: true,
//   pathOverride: '/service-orders/orders',
//   icon: CartIcon,
//   label: 'Service Orders',
//   key: 'service-and-orders',
//   slugOptions: [
//     'SERVICE_AND_ORDERS_BY_ORDERS',
//     'SERVICE_AND_ORDERS_BY_SERVICE',
//   ],
//   permission_slugs: [SCOPES.view_customercompany],
//   hasDivider: true,
//   breadcrumb: ['SERVICE_AND_ORDERS'],
// }
// const SERVICE_AND_ORDERS_BY_ORDERS: RouterMenuProps = {
//   id: 81,
//   path: 'orders',
//   parent_id: 8,

//   label: 'By Order',
//   key: 'service-and-orders-by-order',
//   permission_slugs: [SCOPES.view_customercompany],
//   breadcrumb: ['SERVICE_AND_ORDERS', 'SERVICE_AND_ORDERS_BY_ORDERS'],
// }
// const SERVICE_AND_ORDERS_BY_SERVICE: RouterMenuProps = {
//   id: 81,
//   path: 'services',
//   parent_id: 8,
//   label: 'By Service',
//   key: 'service-and-orders-by-service',
//   permission_slugs: [SCOPES.view_customercompany],
//   breadcrumb: ['SERVICE_AND_ORDERS', 'SERVICE_AND_ORDERS_BY_SERVICE'],
// }
const SERVICE_AND_ORDERS_BY_ORDERS: RouterMenuProps = {
  id: 81,
  path: '/orders',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Orders',
  icon: 'cart-icon',
  key: 'order',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  slugOptions: [
    'SERVICE_AND_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
    'SERVICE_AND_ORDERS_BY_ORDERS_INVOICES',
    'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
    'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
    'SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
    'SERVICE_AND_ORDERS_BY_ORDERS_INVOICES_ID',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_TRANSACTION',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_DETAILS: RouterMenuProps = {
  id: 811,
  path: '/orders/:id/',
  parent_id: 81,
  isDetails: true,
  icon: 'cart-icon',
  label: 'Order Details',
  key: 'order-details',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_NOTES: RouterMenuProps = {
  id: 8004,
  path: 'notes',
  parent_id: 81,
  icon: 'user',
  label: 'Notes',
  key: 'order-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.order_note)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_NOTES',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY: RouterMenuProps = {
  id: 8111,
  path: 'summary',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Summary',
  key: 'order-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_SERVICES: RouterMenuProps = {
  id: 8112,
  path: 'services',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Services',
  key: 'order-details-services',
  permission_slugs: [...handleViewPermission(MODULES.orderService)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS: RouterMenuProps = {
  id: 8113,
  path: 'payments',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Payments', //label: 'Allocate Payments',
  key: 'order-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.order_payment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_TRANSACTION: RouterMenuProps = {
  id: 8113,
  path: 'payments/:trans_id',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Payments', //label: 'Allocate Payments',
  key: 'order-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.order_payment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT: RouterMenuProps = {
  id: 8154,
  path: 'order/payment/:id/receipt',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Payments', //label: 'Allocate Payments',
  key: 'order-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.order_receipt)],
}
const SERVICE_AND_ORDERS_BY_ORDERS_INVOICES: RouterMenuProps = {
  id: 8114,
  path: 'invoices',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Invoices',
  key: 'order-details-invoices',
  permission_slugs: [...handleViewPermission(MODULES.order_invoice)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_INVOICES',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_LOGS: RouterMenuProps = {
  id: 8116,
  path: 'event-logs',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Event Logs',
  key: 'order-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.order_eventlog)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_FILES: RouterMenuProps = {
  id: 8114,
  path: 'files',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Files',
  key: 'order-details-files',
  permission_slugs: [...handleViewPermission(MODULES.order_attachment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_ACTIVITY_LOGS: RouterMenuProps = {
  id: 8114,
  path: 'activity-logs',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Activity Logs',
  key: 'order-details-activity-logs',
  permission_slugs: [...handleViewPermission(MODULES.order_activitylog)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_ACTIVITY_LOGS',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_TASKS: RouterMenuProps = {
  id: 8114,
  path: 'tasks',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Tasks',
  key: 'order-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.order_task)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS: RouterMenuProps = {
  id: 8115,
  path: 'vendor-orders',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Vendor Orders',
  key: 'order-details-vendor-orders',
  permission_slugs: [],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_INVOICES_ID: RouterMenuProps = {
  id: 8116,
  path: '/orders/:id/invoices/:invo_id',
  parent_id: null,
  icon: 'cart-icon',
  label: 'Invoices',
  key: 'order-details-invoices-id',
  permission_slugs: ['document_order_invoice'],
  hasChild: false,
  slugOptions: [],
}

const SERVICE_AND_ORDERS_BY_SERVICE: RouterMenuProps = {
  id: 81,
  path: '/services',
  isSidebarMenu: true,
  parent_id: null,
  icon: 'services-icon',
  label: 'Services',
  key: 'orderitem',
  permission_slugs: [...handleViewPermission(MODULES.services)],

  slugOptions: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
    'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
    'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
    'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
    'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
    'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
    'SHARE_DELIVERABLES',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
    'SERVICE_AND_ORDERS_BY_SERVICE_DATA_COLLECTION',
  ],
  // breadcrumb: ['SERVICE_AND_ORDERS', 'SERVICE_AND_ORDERS_BY_SERVICE'],
}
const SERVICE_AND_ORDERS_BY_SERVICE_DATA_COLLECTION: RouterMenuProps = {
  id: 23,
  path: 'data-collection',
  parent_id: 81,
  icon: 'data-collection',
  label: 'Data Collection',
  key: 'service-and-orders-by-service-data-collection',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  // permission_slugs: [],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_DATA_COLLECTION',
  ],
}
const EXPORTS: RouterMenuProps = {
  id: 82,
  path: '/exports',
  parent_id: null,
  icon: 'upload',
  label: 'Exports',
  key: 'export',
  permission_slugs: [...handleViewPermission(MODULES.export)],
  // permission_slugs: [SCOPES.view_lead],
  // breadcrumb: ['EXPORTS'],
  isSidebarMenu: true,
  slugOptions: [''],
}
const SERVICE_AND_ORDERS_BY_SERVICE_DETAILS: RouterMenuProps = {
  id: 811,
  path: '/services/:id/',
  parent_id: 81,
  isDetails: true,
  icon: 'services-icon',
  label: 'Service Details',
  key: 'service-details',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY: RouterMenuProps = {
  id: 8111,
  path: 'summary',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Summary',
  key: 'service-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS: RouterMenuProps = {
  id: 8112,
  path: 'activity-progress',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Activity Progress',
  key: 'service-details-activity-progress',
  permission_slugs: [...handleViewPermission(MODULES.services_activity)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS: RouterMenuProps = {
  id: 8113,
  path: 'payments',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Payment Allocations',
  key: 'service-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.services_payment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_FILES: RouterMenuProps = {
  id: 8114,
  path: 'files',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Files',
  key: 'service-details-files',
  permission_slugs: [...handleViewPermission(MODULES.services_attachment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_TASKS: RouterMenuProps = {
  id: 8116,
  path: 'tasks',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Tasks',
  key: 'service-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.services_task)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_LOGS: RouterMenuProps = {
  id: 8117,
  path: 'event-logs',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Event Logs',
  key: 'service-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.services_eventlog)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_NOTES: RouterMenuProps = {
  id: 8115,
  path: 'notes',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Notes',
  key: 'service-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.services_note)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL: RouterMenuProps = {
  id: 8116,
  path: 'payment-request',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Payment Requests',
  key: 'service-details-payment-approval',
  permission_slugs: [],
  //permission_slugs: [...handleViewPermission(MODULES.services_payment_approval)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
  ],
}

//System Settings

const SYSTEM_SETTINGS: RouterMenuProps = {
  id: 9,

  parent_id: null,
  icon: 'settings',
  label: 'System Settings',
  key: 'settings',
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: ['SYSTEM_SETTINGS'],
  isSidebarMenu: isAllFranchisee() ? false : true,
  hasChild: true,
}

//System Settings - Organization

const SYSTEM_SETTINGS_ORGANIZATION: RouterMenuProps = {
  id: 91,
  parent_id: 9,
  label: 'My Franchisee',
  key: 'org',
  isSidebarMenu: isAllFranchisee() ? false : true,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_ORGANIZATION'],
  hasChild: true,
}

// System Settings - Organization -Employees

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES: RouterMenuProps = {
  id: 911,
  parent_id: 91,
  label: 'Employees',
  isSidebarMenu: isAllFranchisee() ? false : true,
  path: '/employees',
  key: 'employee',
  hasChild: false,
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS: RouterMenuProps = {
  id: 9111,
  path: '/employees/:id/',
  parent_id: 911,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Employee Details',
  key: 'employee-details',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO: RouterMenuProps = {
  id: 91111,
  path: 'basic-info',
  parent_id: 9111,
  label: 'Summary',
  key: 'employee-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO: RouterMenuProps = {
  id: 91112,
  path: 'team-info',
  parent_id: 9111,
  // icon: 'cart-icon',
  label: 'Team Info',
  key: 'employee-details-team-info',
  permission_slugs: [...handleViewPermission(MODULES.employeeTeam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE: RouterMenuProps = {
  id: 91113,
  path: 'franchisee',
  parent_id: 9111,
  // icon: 'cart-icon',
  label: 'Franchisee',
  key: 'employee-details-franchisee',
  permission_slugs: [...handleViewPermission(MODULES.employeeFranchisee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE',
  ],
}

//System Settings - Organization -Teams

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS: RouterMenuProps = {
  id: 912,

  parent_id: 91,
  label: 'Teams',
  isSidebarMenu: true,
  path: '/teams',
  key: 'employeeteam',
  hasChild: false,
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS: RouterMenuProps = {
  id: 9121,
  path: '/teams/:id/',
  parent_id: 912,
  isDetails: true,
  hasChild: true,
  label: 'Team Details',
  key: 'team-details',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO: RouterMenuProps = {
  id: 91211,
  path: 'info',
  parent_id: 9121,
  // icon: 'cart-icon',
  label: 'Summary',
  key: 'team-details-info',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER: RouterMenuProps = {
  id: 91212,
  path: 'members',
  parent_id: 9121,
  label: 'Members',
  key: 'team-details-members',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam_member)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE: RouterMenuProps = {
  id: 91213,
  path: 'services',
  parent_id: 9121,
  label: 'Services',
  key: 'team-details-services',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam_service)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
  ],
}

//System Settings - Organization - Roles

const SYSTEM_SETTINGS_ORGANIZATION_ROLES: RouterMenuProps = {
  id: 913,

  parent_id: 91,
  label: 'Roles',
  isSidebarMenu: true,
  path: '/roles',
  key: 'group',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  // breadcrumb: [
  //   'SYSTEM_SETTINGS',
  //   'SYSTEM_SETTINGS_ORGANIZATION',
  //   'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
  // ],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
  ],
}
//System Settings - Organization - Roles

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS: RouterMenuProps = {
  id: 914,

  parent_id: 91,
  label: 'Stakeholder',
  isSidebarMenu: true,
  path: '/stakeholders',
  key: 'stakeholder',
  permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
  ],
}
const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS: RouterMenuProps = {
  id: 9141,
  path: '/stakeholders/:id/',
  parent_id: 914,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Stakeholder Details',
  key: 'stakeholder-details',
  permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY: RouterMenuProps =
  {
    id: 91411,
    path: 'summary',
    parent_id: 9141,
    // icon: 'cart-icon',
    label: 'Summary',
    key: 'stakeholder-details-summary',
    permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
    breadcrumb: [
      'SYSTEM_SETTINGS',
      'SYSTEM_SETTINGS_ORGANIZATION',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
    ],
  }

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS: RouterMenuProps =
  {
    id: 91412,
    path: 'teams',
    parent_id: 9131,
    // icon: 'cart-icon',
    label: 'Teams',
    key: 'stakeholder-details-teams',
    permission_slugs: [...handleViewPermission(MODULES.stakeholderTeam)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_ORGANIZATION',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
    ],
  }

//System Settings - Franchisee

const SYSTEM_SETTINGS_FRANCHISEE: RouterMenuProps = {
  id: 100,
  parent_id: 9,
  label: 'Franchisee Listing',
  path: '/franchisee',
  key: 'franchisee',
  isSidebarMenu: true,
  permission_slugs: [SCOPES.employee_dashboard],
  // breadcrumb: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_FRANCHISEE'],
  hasChild: false,
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_SUMMARY',
    'SYSTEM_SETTINGS_FRANCHISEE_SERVICE',
    'SYSTEM_SETTINGS_FRANCHISEE_EMPLOYEES',
    'SYSTEM_SETTINGS_FRANCHISEE_PAYMENT_GATEWAY',
    'SYSTEM_SETTINGS_FRANCHISEE_COMMUNICATION',
  ],
}

const SYSTEM_SETTINGS_FRANCHISEE_DETAILS: RouterMenuProps = {
  id: 131,
  parent_id: 100,
  label: 'Franchisee Details',
  path: '/franchisee/:id/',
  key: 'franchisee-details',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
  ],
  hasChild: false,
  isDetails: true,
}

const SYSTEM_SETTINGS_FRANCHISEE_SUMMARY: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Summary',
  path: 'basic-info',
  key: 'franchisee-details-basic-info',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_SUMMARY',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_FRANCHISEE_SERVICE: RouterMenuProps = {
  id: 133,
  parent_id: 131,
  label: 'Service',
  path: 'allocated-service',
  key: 'franchisee-details-allocated-service',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_SERVICE',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_FRANCHISEE_EMPLOYEES: RouterMenuProps = {
  id: 134,
  parent_id: 131,
  label: 'Employees',
  path: 'allocated-employees',
  key: 'franchisee-details-allocated-employees',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_EMPLOYEES',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_FRANCHISEE_PAYMENT_GATEWAY: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Payment Gateway',
  path: 'payment-gateway',
  key: 'franchisee-details-payment-gateway',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_PAYMENT_GATEWAY',
  ],
  hasChild: false,
}
const SYSTEM_SETTINGS_FRANCHISEE_COMMUNICATION: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Communication',
  path: 'communication',
  key: 'franchisee-details-communication',
  isSidebarMenu: false,
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_COMMUNICATION',
  ],
  hasChild: false,
}
const SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT: RouterMenuProps = {
  id: 191,
  parent_id: 131,
  label: 'Bank Account',
  path: 'bank-account',
  key: 'franchisee-details-bank-account',
  breadcrumb_path: '/franchisee/:id/bank-account',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT',
  ],
  hasChild: false,
}
const SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_DETAILS: RouterMenuProps = {
  id: 192,
  parent_id: 191,
  isDetails: true,
  label: 'Bank Account Details',
  path: '/franchisee/:id/bank-account/:id2',
  key: 'franchisee-bank-account-details',
  breadcrumb_path: '/franchisee/:id/bank-account/:id2/basic-info',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_DETAILS',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_BASIC_INFO: RouterMenuProps = {
  id: 193,
  path: 'basic-info',
  breadcrumb_path: '/franchisee/:id/bank-account/:id2/basic-info',
  parent_id: 192,
  label: 'Summary',
  key: 'bank-account-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_FRANCHISEE',
    'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_DETAILS',
    'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_BASIC_INFO',
  ],
}
//System Settings - Vendors

const SYSTEM_SETTINGS_VENDORS: RouterMenuProps = {
  id: 100,
  parent_id: 9,
  label: 'Vendors',
  path: '/vendors',
  key: 'franchisee',
  isSidebarMenu: true,
  permission_slugs: [],
  // breadcrumb: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_FRANCHISEE'],
  hasChild: false,
  slugOptions: [
    'SYSTEM_SETTINGS',

    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_SUMMARY',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO',

    'SYSTEM_SETTINGS_VENDOR_ROLE',
    'SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY',
    'SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
    'SYSTEM_SETTINGS_VENDOR_TEAM',
    'SYSTEM_SETTINGS_VENDOR_TEAMDETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY',
    'SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS',
  ],
}

const SYSTEM_SETTINGS_VENDOR_DETAILS: RouterMenuProps = {
  id: 131,
  parent_id: 100,
  label: 'Vendor Details',
  path: '/vendors/:id/',
  breadcrumb_path: '/vendors/:id/basic-info',
  key: 'franchisee-details',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_SUMMARY: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Summary',
  path: 'basic-info',
  key: 'vendor-details-basic-info',
  breadcrumb_path: '/vendors/:id/basic-info',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_SUMMARY',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_SERVICE: RouterMenuProps = {
  id: 133,
  parent_id: 131,
  label: 'Service',
  path: 'service',
  key: 'vendor-details-services',
  breadcrumb_path: '/vendors/:id/service',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT: RouterMenuProps = {
  id: 188,
  parent_id: 131,
  label: 'Bank Account',
  path: 'bank-account',
  key: 'vendor-details-bank-account',
  breadcrumb_path: '/vendors/:id/bank-account',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_DETAILS: RouterMenuProps = {
  id: 189,
  parent_id: 188,
  isDetails: true,
  label: 'Bank Account Details',
  path: '/vendors/:id/bank-account/:id2',
  key: 'vendor-bank-account-details',
  breadcrumb_path: '/vendors/:id/bank-account/:id2/basic-info',
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_DETAILS',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO: RouterMenuProps = {
  id: 190,
  path: 'basic-info',
  breadcrumb_path: '/vendors/:id/bank-account/:id2/basic-info',
  parent_id: 189,
  label: 'Summary',
  key: 'bank-account-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_VENDOR_EMPLOYEES: RouterMenuProps = {
  id: 134,
  parent_id: 131,
  label: 'Employees',
  path: 'employees',
  key: 'vendor-details-employees',
  isSidebarMenu: false,
  breadcrumb_path: '/vendors/:id/employees',
  // isDetails: true,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS: RouterMenuProps = {
  id: 9111,
  path: '/vendors/:id/employees/:id2',
  breadcrumb_path: '/vendors/:id/employees/:id2/basic-info',
  parent_id: 911,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Employee Details',
  key: 'employee-details',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO: RouterMenuProps = {
  id: 91111,
  path: 'basic-info',
  breadcrumb_path: '/vendors/:id/employees/:id2/basic-info',
  parent_id: 9111,
  label: 'Summary',
  key: 'employee-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO: RouterMenuProps = {
  id: 91112,
  path: 'team-info',
  breadcrumb_path: '/vendors/:id/employees/:id2/team-info',
  parent_id: 9111,
  // icon: 'cart-icon',
  label: 'Team Info',
  key: 'employee-details-team-info',
  permission_slugs: [...handleViewPermission(MODULES.employeeTeam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO',
  ],
}

const SYSTEM_SETTINGS_VENDOR_ROLE: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Roles',
  path: 'roles',
  breadcrumb_path: '/vendors/:id/roles',
  key: 'vendor-details-roles',
  isSidebarMenu: false,
  // isDetails: true,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE',
  ],
  hasChild: false,
}
const SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS: RouterMenuProps = {
  id: 9111,
  path: '/vendors/:id/roles/:id2',
  breadcrumb_path: '/vendors/:id/roles/:id2/basic-info',
  parent_id: 911,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Role Details',
  key: 'vendor-role-details',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE',
    'SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS',
  ],
}
const SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY: RouterMenuProps = {
  id: 91111,
  path: 'basic-info',
  breadcrumb_path: '/vendors/:id/roles/:id2/basic-info',
  parent_id: 9111,
  label: 'Summary',
  key: 'vendor-role-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE',
    'SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY',
  ],
}

const SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS: RouterMenuProps = {
  id: 91112,
  breadcrumb_path: '/vendors/:id/roles/:id2/permissions',
  path: 'permissions',
  parent_id: 9111,
  // icon: 'cart-icon',
  label: 'Permission',
  key: 'vendor-role-details-permissions',
  permission_slugs: [...handleViewPermission(MODULES.employeeTeam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE',
    'SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS',
  ],
}
const SYSTEM_SETTINGS_VENDOR_TEAM: RouterMenuProps = {
  id: 132,
  parent_id: 131,
  label: 'Teams',
  path: 'teams',
  breadcrumb_path: '/vendors/:id/teams',
  key: 'vendor-details-team',
  // isDetails: true,
  isSidebarMenu: false,
  permission_slugs: [],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM',
  ],
  hasChild: false,
}

const SYSTEM_SETTINGS_VENDOR_TEAMDETAILS: RouterMenuProps = {
  id: 9111,
  path: '/vendors/:id/teams/:id2',
  breadcrumb_path: '/vendors/:id/teams/:id2/basic-info',
  parent_id: 911,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Team Details',
  key: 'vendor-team-details',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM',
    'SYSTEM_SETTINGS_VENDOR_TEAMDETAILS',
  ],
}
const SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY: RouterMenuProps = {
  id: 91111,
  path: 'basic-info',
  parent_id: 9111,
  label: 'Summary',
  key: 'vendor-team-details-basic-info',
  breadcrumb_path: '/vendors/:id/teams/:id2/basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM',
    'SYSTEM_SETTINGS_VENDOR_TEAMDETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY',
  ],
}

const SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS: RouterMenuProps = {
  id: 91112,
  path: 'members',
  breadcrumb_path: '/vendors/:id/teams/:id2/members',
  parent_id: 9111,
  // icon: 'cart-icon',
  label: 'Members',
  key: 'vendor-team-details-members',
  permission_slugs: [...handleViewPermission(MODULES.employeeTeam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_VENDORS',
    'SYSTEM_SETTINGS_VENDOR_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM',
    'SYSTEM_SETTINGS_VENDOR_TEAMDETAILS',
    'SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS: RouterMenuProps = {
  id: 9131,
  path: '/roles/:id/',
  parent_id: 913,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Role Details',
  key: 'employee-details',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY: RouterMenuProps = {
  id: 91311,
  path: 'basic-info',
  parent_id: 9131,
  // icon: 'cart-icon',
  label: 'Summary',
  key: 'role-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  breadcrumb: [
    // 'SYSTEM_SETTINGS',
    // 'SYSTEM_SETTINGS_ORGANIZATION',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS: RouterMenuProps =
  {
    id: 91312,
    path: 'permissions',
    parent_id: 9131,
    // icon: 'cart-icon',
    label: 'Permissions',
    key: 'role-details-permissions',
    permission_slugs: [...handleViewPermission(MODULES.group)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_ORGANIZATION',
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
    ],
  }

// System Settings - Data Management

const SYSTEM_SETTINGS_DATA_MANAGEMENT: RouterMenuProps = {
  id: 94,

  parent_id: 9,
  label: 'Data Management',
  key: 'data-management',
  isSidebarMenu: true,
  permission_slugs: [SCOPES.employee_dashboard],
  hasChild: true,
}

// System Settings - Data Management - Dropdowns

const SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS: RouterMenuProps = {
  id: 941,

  parent_id: 94,
  label: 'Dropdowns',
  isSidebarMenu: true,
  path: '/dropdowns',
  pathOverride: '/dropdowns/constitution-categories',
  key: 'data-management-dropdowns',
  permission_slugs: [SCOPES.employee],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS',
  ],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_CONSTITUTION_CATEGORIES',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_CATEGORIES',
    'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_BUSINESS_SECTOR',
  ],
}
const SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_CONSTITUTION_CATEGORIES: RouterMenuProps =
  {
    id: 9411,
    path: '/dropdowns/constitution-categories',
    parent_id: 941,
    hasChild: true,
    label: 'Constitution Categories',
    key: 'data-management-dropdowns-constitution',
    permission_slugs: [SCOPES.employee],
  }

const SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_CATEGORIES: RouterMenuProps =
  {
    id: 9412,
    path: '/dropdowns/service-categories',
    parent_id: 941,
    hasChild: true,
    label: 'Service Categories',
    key: 'data-management-service-constitution',
    permission_slugs: [SCOPES.employee],
  }

const SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_BUSINESS_SECTOR: RouterMenuProps =
  {
    id: 9413,
    path: '/dropdowns/business-sector',
    parent_id: 941,
    hasChild: true,
    label: 'Business Sector',
    key: 'data-management-business-sector',
    permission_slugs: [SCOPES.employee],
  }

//System Settings - Service Management

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT: RouterMenuProps = {
  id: 92,
  parent_id: 9,
  label: 'Service Management',
  key: 'service-management',
  isSidebarMenu: true,
  permission_slugs: ['admin_only'],
  breadcrumb: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT'],
  hasChild: true,
}
const SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT: RouterMenuProps = {
  id: 96,
  parent_id: 9,
  label: 'Supplier Management',
  key: 'supplier-management',
  isSidebarMenu: true,
  permission_slugs: ['admin_only'],
  breadcrumb: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT'],
  hasChild: true,
}
const SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER: RouterMenuProps = {
  id: 961,
  path: '/supplier',
  parent_id: 96,
  isSidebarMenu: true,
  label: 'Supplier',
  key: 'suppliers',
  permission_slugs: ['admin_only'],
  // breadcrumb: [
  //   'SYSTEM_SETTINGS',
  //   'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT',
  //   'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER',
  // ],
}

const SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER_CATEGORIES: RouterMenuProps =
  {
    id: 962,
    path: '/supplier-categories',
    parent_id: 96,
    label: 'Supplier Categories',
    key: 'supplier-categories',
    isSidebarMenu: true,
    permission_slugs: ['admin_only'],
    // breadcrumb: [
    //   'SYSTEM_SETTINGS',
    //   'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT',
    //   'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER_CATEGORIES',
    // ],
  }

// System Settings - Organization - Services

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES: RouterMenuProps = {
  id: 921,
  parent_id: 92,
  label: 'Services',
  isSidebarMenu: true,
  path: '/service-management/service',
  key: 'services',
  hasChild: false,
  permission_slugs: ['admin_only'],
  // permission_slugs: [SCOPES.employee_dashboard],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_CHECKLIST',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_ELIGIBILITY_CRITERIA',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DOCUMENT_REQUIRED',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_PROCESS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_STATUS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_DATA_COLLECTION',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_APPLICABILITY',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_GUIDELINES',
  ],
}

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS: RouterMenuProps = {
  id: 9211,
  path: '/service-management/service/:id',
  parent_id: 921,
  isDetails: true,
  hasChild: true,
  // icon: 'cart-icon',
  label: 'Service Details',
  key: 'services-details',
  permission_slugs: ['admin_only'],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO: RouterMenuProps =
  {
    id: 92111,
    path: 'summary',
    parent_id: 9211,
    label: 'Summary',
    key: 'services-details-basic-info',
    permission_slugs: [...handleViewPermission(MODULES.employee)],
    breadcrumb: [
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO',
    ],
  }

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_CHECKLIST: RouterMenuProps = {
  id: 92112,
  path: 'activity',
  parent_id: 9211,
  label: 'Activity',
  key: 'services-details-activity',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    // 'SYSTEM_SETTINGS',
    // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_CHECKLIST',
  ],
}

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_ELIGIBILITY_CRITERIA: RouterMenuProps =
  {
    id: 92114,
    path: 'eligibility-criteria',
    parent_id: 9211,
    label: 'Eligibility Criteria',
    key: 'services-details-eligibility-criteria',
    permission_slugs: [...handleViewPermission(MODULES.employee)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_ELIGIBILITY_CRITERIA',
    ],
  }

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DOCUMENT_REQUIRED: RouterMenuProps =
  {
    id: 92115,
    path: 'document-required',
    parent_id: 9211,
    label: 'Document Required',
    key: 'services-details-document-required',
    permission_slugs: [...handleViewPermission(MODULES.employee)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DOCUMENT_REQUIRED',
    ],
  }

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_PROCESS: RouterMenuProps = {
  id: 92116,
  path: 'process',
  parent_id: 9211,
  label: 'Process',
  key: 'services-details-process',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    // 'SYSTEM_SETTINGS',
    // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_PROCESS',
  ],
}
const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_APPLICABILITY: RouterMenuProps =
  {
    id: 92117,
    path: 'applicability',
    parent_id: 9211,
    label: 'Applicability',
    key: 'services-details-applicability',
    permission_slugs: [...handleViewPermission(MODULES.employee)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_APPLICABILITY',
    ],
  }
const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_GUIDELINES: RouterMenuProps =
  {
    id: 92118,
    path: 'guidelines',
    parent_id: 9211,
    label: 'Guidelines',
    key: 'services-details-guidelines',
    permission_slugs: [...handleViewPermission(MODULES.employee)],
    breadcrumb: [
      // 'SYSTEM_SETTINGS',
      // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
      'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_GUIDELINES',
    ],
  }

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_STATUS: RouterMenuProps = {
  id: 92113,
  path: 'status',
  parent_id: 9211,
  label: 'Status',
  key: 'services-details-status',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    // 'SYSTEM_SETTINGS',
    // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_STATUS',
  ],
}
const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_DATA_COLLECTION: RouterMenuProps = {
  id: 92113,
  path: 'data-collection',
  parent_id: 9211,
  label: 'Data Collection Form',
  key: 'services-details-datacollection',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    // 'SYSTEM_SETTINGS',
    // 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_DATA_COLLECTION',
  ],
}

// System Settings - Organization - Activities

const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_ACTIVITIES: RouterMenuProps = {
  id: 921,
  parent_id: 92,
  label: 'Activities',
  isSidebarMenu: true,
  path: '/service-management/activities',
  key: 'activities',
  hasChild: false,
  permission_slugs: ['admin_only'],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
    'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_ACTIVITIES',
  ],
}

//System Settings - Services

// const SYSTEM_SETTINGS_SERVICES: RouterMenuProps = {
//   id: 92,

//   parent_id: 9,
//   label: 'Services',
//   key: 'services',
//   isSidebarMenu: true,
//   permission_slugs: [SCOPES.employee_dashboard],
//   hasChild: true,
// }

//System Settings - Services - Services

// const SYSTEM_SETTINGS_SERVICES_SERVICES: RouterMenuProps = {
//   id: 921,

//   parent_id: 92,
//   label: 'Services',
//   isSidebarMenu: true,
//   path: '/services',
//   key: 'services-services',
//   permission_slugs: [SCOPES.employee],
//   breadcrumb: [
//     'SYSTEM_SETTINGS',
//     'SYSTEM_SETTINGS_SERVICES',
//     'SYSTEM_SETTINGS_SERVICES_SERVICES',
//   ],
// }

//System Settings - Services - Activities

// const SYSTEM_SETTINGS_SERVICES_ACTIVITIES: RouterMenuProps = {
//   id: 921,

//   parent_id: 92,
//   label: 'Activities',
//   isSidebarMenu: true,
//   path: '/activities',
//   key: 'services-activities',
//   permission_slugs: [SCOPES.employee],
//   breadcrumb: [
//     'SYSTEM_SETTINGS',
//     'SYSTEM_SETTINGS_SERVICES',
//     'SYSTEM_SETTINGS_SERVICES_ACTIVITIES',
//   ],
// }

//System Settings - Promotions

// const SYSTEM_SETTINGS_PROMOTIONS: RouterMenuProps = {
//   id: 93,

//   parent_id: 9,
//   label: 'Promotions',
//   key: 'promotions',
//   isSidebarMenu: true,
//   permission_slugs: [SCOPES.employee_dashboard],
//   hasChild: true,
// }

//System Settings - Promotions - Coupons

// const SYSTEM_SETTINGS_PROMOTIONS_COUPONS: RouterMenuProps = {
//   id: 931,

//   parent_id: 93,
//   label: 'Coupons',
//   isSidebarMenu: true,
//   path: '/coupons',
//   key: 'promotions-coupons',
//   permission_slugs: [SCOPES.employee],
//   breadcrumb: [
//     'SYSTEM_SETTINGS',
//     'SYSTEM_SETTINGS_PROMOTIONS',
//     'SYSTEM_SETTINGS_PROMOTIONS_COUPONS',
//   ],
// }

//System Settings - Data Management

//System Settings - Forms

// const SYSTEM_SETTINGS_FORMS: RouterMenuProps = {
//   id: 95,

//   parent_id: 9,
//   label: 'Forms',
//   key: 'forms',
//   isSidebarMenu: true,
//   permission_slugs: [SCOPES.employee_dashboard],
//   hasChild: true,
// }

//System Settings - Forms - Business Forms

// const SYSTEM_SETTINGS_FORMS_BUSINESS_FORMS: RouterMenuProps = {
//   id: 951,

//   parent_id: 95,
//   label: 'Business Forms',
//   isSidebarMenu: true,
//   path: '/business-forms',
//   key: 'forms-business-forms',
//   permission_slugs: [SCOPES.employee],
//   breadcrumb: [
//     'SYSTEM_SETTINGS',
//     'SYSTEM_SETTINGS_FORMS',
//     'SYSTEM_SETTINGS_FORMS_BUSINESS_FORMS',
//   ],
// }
// PROSPECTS
const PROSPECTS: RouterMenuProps = {
  id: 15,
  path: '/prospects',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Prospects',
  icon: 'prospect',
  key: 'prospect',
  permission_slugs: [...handleViewPermission(MODULES.prospect)],
  slugOptions: [
    'PROSPECT_MERGE',
    'PROSPECT_DETAILS',
    'PROSPECT_DETAILS_SUMMARY',
    'PROSPECT_DETAILS_NOTES',
    'PROSPECT_DETAILS_EVENT_LOGS',
    'PROSPECT_DETAILS_TASKS',
    'PROSPECT_DETAILS_FILES',
    'PROSPECT_DETAILS_POSSIBLE_DUPLICATES',
    'PROSPECT_DETAILS_SERVICES',
  ],
}
const PROSPECT_MERGE: RouterMenuProps = {
  id: 1520,
  path: '/prospects/merge',
  parent_id: 10,
  icon: 'prospect',
  label: 'Merge',
  key: 'prospect-merge',
  permission_slugs: [MODULES.merge_prospect],
}
const PROSPECT_DETAILS: RouterMenuProps = {
  id: 151,
  path: '/prospects/:id/',
  parent_id: 10,
  isDetails: true,
  icon: 'prospect',
  label: 'Prospects Details',
  key: 'prospect-details',
  permission_slugs: [...handleViewPermission(MODULES.prospect)],

  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS'],
}

const PROSPECT_DETAILS_SUMMARY: RouterMenuProps = {
  id: 1511,
  path: 'summary',
  parent_id: 101,
  icon: 'prospect',
  label: 'Summary',
  key: 'prospect_summary',
  permission_slugs: [...handleViewPermission(MODULES.prospect)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_SUMMARY'],
}

const PROSPECT_DETAILS_NOTES: RouterMenuProps = {
  id: 1512,
  path: 'notes',
  parent_id: 101,
  icon: 'prospect',
  label: 'Notes',
  key: 'prospect_note',
  permission_slugs: [...handleViewPermission(MODULES.prospectNote)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_NOTES'],
}

const PROSPECT_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 1513,
  path: 'event-logs',
  parent_id: 101,
  icon: 'prospect',
  label: 'Event Logs',
  key: 'prospect-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.prospectEventlog)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_EVENT_LOGS'],
}

const PROSPECT_DETAILS_TASKS: RouterMenuProps = {
  id: 1514,
  path: 'tasks',
  parent_id: 101,
  icon: 'prospect',
  label: 'Tasks',
  key: 'prospect-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.prospectTask)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_TASKS'],
}
const PROSPECT_DETAILS_FILES: RouterMenuProps = {
  id: 1515,
  path: 'files',
  parent_id: 101,
  icon: 'prospect',
  label: 'Files',
  key: 'prospect-details-files',
  permission_slugs: [...handleViewPermission(MODULES.prospectAttachment)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_FILES'],
}
const PROSPECT_DETAILS_POSSIBLE_DUPLICATES: RouterMenuProps = {
  id: 1516,
  path: 'duplicates',
  parent_id: 101,
  icon: 'prospect',
  label: 'Possible Duplicates',
  key: 'prospect-details-duplicates',
  permission_slugs: [...handleViewPermission(MODULES.deal_task)],
  breadcrumb: [
    'PROSPECTS',
    'PROSPECT_DETAILS',
    'PROSPECT_DETAILS_POSSIBLE_DUPLICATES',
  ],
}
const PROSPECT_DETAILS_SERVICES: RouterMenuProps = {
  id: 1516,
  path: 'services',
  parent_id: 101,
  icon: 'prospects',
  label: 'Services',
  key: 'prospect-details-services',
  permission_slugs: [...handleViewPermission(MODULES.prospect_service)],
  breadcrumb: ['PROSPECTS', 'PROSPECT_DETAILS', 'PROSPECT_DETAILS_SERVICES'],
}
//DEALS
const DEALS: RouterMenuProps = {
  id: 10,
  path: '/deals',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Deals',
  icon: 'deals',
  key: 'deal',
  permission_slugs: [...handleViewPermission(MODULES.deal)],
  slugOptions: [
    'DEAL_MERGE',
    'DEAL_DETAILS',
    'DEAL_DETAILS_SUMMARY',
    'DEAL_DETAILS_NOTES',
    'DEAL_DETAILS_EVENT_LOGS',
    'DEAL_DETAILS_TASKS',
    'DEAL_DETAILS_FILES',
    'DEAL_DETAILS_POSSIBLE_DUPLICATES',
    'DEAL_DETAILS_SERVICES',
  ],
}
const DEAL_MERGE: RouterMenuProps = {
  id: 1020,
  path: '/deals/merge',
  parent_id: 10,
  icon: 'merge',
  label: 'Merge',
  key: 'deal-merge',
  permission_slugs: [MODULES.merge_deal],
}
const DEAL_DETAILS: RouterMenuProps = {
  id: 101,
  path: '/deals/:id/',
  parent_id: 10,
  isDetails: true,
  icon: 'deals',
  label: 'Deal Details',
  key: 'deal-details',
  permission_slugs: [...handleViewPermission(MODULES.deal)],

  breadcrumb: ['DEALS', 'DEAL_DETAILS'],
}

const DEAL_DETAILS_SUMMARY: RouterMenuProps = {
  id: 1011,
  path: 'summary',
  parent_id: 101,
  icon: 'deals',
  label: 'Summary',
  key: 'deal_summary',
  permission_slugs: [...handleViewPermission(MODULES.deal)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_SUMMARY'],
}

const DEAL_DETAILS_NOTES: RouterMenuProps = {
  id: 1012,
  path: 'notes',
  parent_id: 101,
  icon: 'deals',
  label: 'Notes',
  key: 'deal_note',
  permission_slugs: [...handleViewPermission(MODULES.deal_note)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_NOTES'],
}

const DEAL_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 1013,
  path: 'event-logs',
  parent_id: 101,
  icon: 'deals',
  label: 'Event Logs',
  key: 'deal-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.deal_eventlog)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_EVENT_LOGS'],
}

const DEAL_DETAILS_TASKS: RouterMenuProps = {
  id: 1014,
  path: 'tasks',
  parent_id: 101,
  icon: 'deals',
  label: 'Tasks',
  key: 'deal-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.deal_task)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_TASKS'],
}
const DEAL_DETAILS_FILES: RouterMenuProps = {
  id: 1015,
  path: 'files',
  parent_id: 101,
  icon: 'deals',
  label: 'Files',
  key: 'deal-details-files',
  permission_slugs: [...handleViewPermission(MODULES.deal_attachment)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_FILES'],
}
const DEAL_DETAILS_POSSIBLE_DUPLICATES: RouterMenuProps = {
  id: 1016,
  path: 'duplicates',
  parent_id: 101,
  icon: 'deals',
  label: 'Possible Duplicates',
  key: 'deal-details-duplicates',
  permission_slugs: [...handleViewPermission(MODULES.deal_task)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_POSSIBLE_DUPLICATES'],
}
const DEAL_DETAILS_SERVICES: RouterMenuProps = {
  id: 1016,
  path: 'services',
  parent_id: 101,
  icon: 'deals',
  label: 'Services',
  key: 'deal-details-services',
  permission_slugs: [...handleViewPermission(MODULES.deal_service)],
  breadcrumb: ['DEALS', 'DEAL_DETAILS', 'DEAL_DETAILS_SERVICES'],
}
//DEALS

// QUOTES
const QUOTES: RouterMenuProps = {
  id: 11,
  path: '/quotes',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Quotes',
  icon: 'quote',
  key: 'quote',
  permission_slugs: [...handleViewPermission(MODULES.quote)],
  slugOptions: [
    'QUOTE_DETAILS',
    'QUOTE_DETAILS_SUMMARY',
    'QUOTE_DETAILS_NOTES',
    'QUOTE_DETAILS_DOCUMENT',
    'QUOTE_DETAILS_FILES',
    'QUOTE_DETAILS_SERVICES',
    'QUOTE_DETAILS_DOCUMENT_ID',
    'QUOTE_TASKS',
    'QUOTE_DETAILS_EVENT_LOGS',
  ],
  hasDivider: true,
}

const QUOTE_DETAILS: RouterMenuProps = {
  id: 111,
  path: '/quotes/:id/',
  parent_id: 11,
  isDetails: true,
  icon: 'quote',
  label: 'Quote Details',
  key: 'deal-details',
  permission_slugs: [...handleViewPermission(MODULES.quote)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS'],
}

const QUOTE_DETAILS_SUMMARY: RouterMenuProps = {
  id: 1111,
  path: 'summary',
  parent_id: 111,
  icon: 'quote',
  label: 'Summary',
  key: 'quote-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.quote)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_SUMMARY'],
}

const QUOTE_DETAILS_NOTES: RouterMenuProps = {
  id: 1112,
  path: 'notes',
  parent_id: 111,
  icon: 'quote',
  label: 'Notes',
  key: 'quote-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.quote_note)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_NOTES'],
}
const QUOTE_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 4001,
  path: 'event-logs',
  parent_id: 41,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.quote_eventlog)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_EVENT_LOGS'],
}

const QUOTE_TASKS: RouterMenuProps = {
  id: 4003,
  path: 'tasks',
  parent_id: 41,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.quote_task)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_TASKS'],
}

const QUOTE_DETAILS_FILES: RouterMenuProps = {
  id: 1113,
  path: 'files',
  parent_id: 111,
  icon: 'quote',
  label: 'Files',
  key: 'quote-details-files',
  permission_slugs: [...handleViewPermission(MODULES.quote_attachment)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_FILES'],
}

const QUOTE_DETAILS_DOCUMENT: RouterMenuProps = {
  id: 1115,
  path: 'documents',
  parent_id: 111,
  icon: 'quote',
  label: 'Quote Document',
  key: 'quote-details-documents',
  permission_slugs: [...handleViewPermission(MODULES.quote_quotedocument)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_DOCUMENT'],
}

const QUOTE_DETAILS_DOCUMENT_ID: RouterMenuProps = {
  id: 1116,
  path: '/quotes/:id/documents/:quote_id',
  parent_id: null,
  icon: 'quote',
  label: 'Quote Document',
  key: 'quote-details-documents-id',
  permission_slugs: [...handleViewPermission(MODULES.quote_quotation)],
  hasChild: false,
  slugOptions: [],
}

const QUOTE_DETAILS_SERVICES: RouterMenuProps = {
  id: 1114,
  path: 'services',
  parent_id: 111,
  icon: 'quote',
  label: 'Services',
  key: 'quote-details-services',
  permission_slugs: [...handleViewPermission(MODULES.quote_service)],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_SERVICES'],
}

const QUOTE_DETAILS_ACTIVITY_LOGS: RouterMenuProps = {
  id: 1115,
  path: 'activity-logs',
  parent_id: 111,
  icon: 'quote',
  label: 'Activity Logs',
  key: 'quote-details-activity-logs',
  permission_slugs: [],
  breadcrumb: ['QUOTES', 'QUOTE_DETAILS', 'QUOTE_DETAILS_ACTIVITY_LOGS'],
}

const ACTIVITIES: RouterMenuProps = {
  id: 12,
  path: '/activities',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Activities',
  icon: 'activities',
  hasDivider: true,
  key: 'orderitemtaskemployee',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  slugOptions: [
    'ACTIVITIES',
    'ACTIVITY_DETAILS',
    'ACTIVITY_DETAILS_SUMMARY',
    'ACTIVITY_DETAILS_NOTES',
    'ACTIVITY_DETAILS_REQUESTS',
    'ACTIVITY_DETAILS_FILES',
    'ACTIVITY_DETAILS_LOGS',
    'ACTIVITY_DETAILS_TASKS',
  ],
}
const ACTIVITY_DETAILS: RouterMenuProps = {
  id: 101,
  path: '/activities/:id/',
  parent_id: 10,
  isDetails: true,
  icon: 'activities',
  label: 'Activity Details',
  key: 'activities-details',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS'],
}

const SHARE_DELIVERABLES: RouterMenuProps = {
  id: 101,
  path: '/share-deliverables/:id/',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Service Deliverables',
  key: 'share-deliverables',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SHARE_DELIVERABLES_SUMMARY',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_DETAILS',
  ],
}
const SHARE_DELIVERABLES_SUMMARY: RouterMenuProps = {
  id: 80001,
  path: 'summary',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Summary',
  key: 'share-deliverables-summary',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_SUMMARY',
  ],
}
const SHARE_DELIVERABLES_DETAILS: RouterMenuProps = {
  id: 80002,
  path: 'deliverables',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Share Deliverables',
  key: 'share-deliverables-details',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_DETAILS',
  ],
}

const ACTIVITY_DETAILS_SUMMARY: RouterMenuProps = {
  id: 1011,
  path: 'summary',
  parent_id: 101,
  icon: 'activities',
  label: 'Summary',
  key: 'activities-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_SUMMARY'],
}

const ACTIVITY_DETAILS_NOTES: RouterMenuProps = {
  id: 1012,
  path: 'notes',
  parent_id: 101,
  icon: 'activities',
  label: 'Notes & Checklist',
  key: 'activities-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.activity_note)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_NOTES'],
}

const ACTIVITY_DETAILS_REQUESTS: RouterMenuProps = {
  id: 1013,
  path: 'requests',
  parent_id: 101,
  icon: 'activities',
  label: 'Activity Request',
  key: 'activities-details-requests',
  permission_slugs: [...handleViewPermission(MODULES.activity_request)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_REQUESTS'],
}

const ACTIVITY_DETAILS_FILES: RouterMenuProps = {
  id: 1015,
  path: 'files',
  parent_id: 101,
  icon: 'activities',
  label: 'Files & Attachments',
  key: 'activities-details-files',
  permission_slugs: [...handleViewPermission(MODULES.activity_attachment)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_FILES'],
}
const ACTIVITY_DETAILS_LOGS: RouterMenuProps = {
  id: 1015,
  path: 'event-logs',
  parent_id: 101,
  icon: 'activities',
  label: 'Event Logs',
  key: 'activities-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.activity_eventlog)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_LOGS'],
}
const ACTIVITY_DETAILS_TASKS: RouterMenuProps = {
  id: 1015,
  path: 'tasks',
  parent_id: 101,
  icon: 'deals',
  label: 'Tasks',
  key: 'activities-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.activity_task)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_TASKS'],
}

const PAYMENTS_RECEIPT: RouterMenuProps = {
  id: 8117,
  path: '/payments/receipt/:id',
  parent_id: null,
  icon: 'cart-icon',
  label: 'reciept',
  key: 'payments-reciept',
  permission_slugs: [...handleViewPermission(MODULES.payment_receipt)],
}
const USER_PROFILE: RouterMenuProps = {
  id: 8118,
  path: '/profile',
  parent_id: null,
  icon: 'cart-icon',
  label: 'reciept',
  key: 'user-profile',
  permission_slugs: [],
}
const NOTIFICATIONS: RouterMenuProps = {
  id: 9999,
  path: '/notifications',
  isSidebarMenu: true,
  parent_id: null,
  icon: 'notify-icon',
  label: 'Notifications',
  hasDivider: true,
  key: 'user-notifications',
  permission_slugs: [],
}
const UNALLOTTED_LEADS: RouterMenuProps = {
  id: 9999,
  path: '/unallotted-leads',
  parent_id: null,
  icon: 'leads-icon',
  label: 'Unallotted leads',
  key: 'unallotted_lead',
  permission_slugs: [...handleViewPermission(MODULES.unallotted_lead)],
  isSidebarMenu: true,
  slugOptions: [
    'UNALLOTTED_LEAD_DETAILS_PROFILE',

    'UNALLOTTED_LEAD_DETAILS',

    'UNALLOTTED_LEADS',
  ],
  hasChild: false,
}
const UNALLOTTED_LEAD_DETAILS: RouterMenuProps = {
  id: 99991,
  path: '/unallotted-leads/:id',
  parent_id: 9999,
  isDetails: true,
  icon: 'user',
  label: 'Lead Details',
  key: 'unallotted-lead-details',
  permission_slugs: [...handleViewPermission(MODULES.unallotted_lead)],
  breadcrumb: ['UNALLOTTED_LEADS', 'UNALLOTTED_LEAD_DETAILS'],
}
const UNALLOTTED_LEAD_DETAILS_PROFILE: RouterMenuProps = {
  id: 99992,
  path: 'summary',
  parent_id: 9999,
  icon: 'user',
  label: 'Summary',
  key: 'unallotted-lead-details-profile',
  permission_slugs: [...handleViewPermission(MODULES.unallotted_lead)],
  breadcrumb: [
    'UNALLOTTED_LEADS',
    'UNALLOTTED_LEAD_DETAILS',
    'UNALLOTTED_LEAD_DETAILS_PROFILE',
  ],
}
// Note : The arrangements of below "router_config" object will reflect in Sidebar
export const router_config: { [key: string]: RouterMenuProps } = {
  LOGIN,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  USER_PROFILE,
  //
  DASHBOARD,
  //
  MY_TASKS,
  //
  MY_APPROVALS,
  //
  MY_PAYMENT_APPROVALS,
  //
  LEADS,
  LEAD_DETAILS,
  LEAD_DETAILS_PROFILE,
  LEAD_MERGE,
  LEAD_DETAILS_NOTES,
  LEAD_DETAILS_POSSIBLE_DUPLICATES,
  LEAD_DETAILS_EVENT_LOGS,
  LEAD_FILES_AND_ATTACHMENTS,
  LEAD_TASKS,
  LEAD_DETAILS_MERGE,
  //

  UNALLOTTED_LEADS,
  UNALLOTTED_LEAD_DETAILS,
  UNALLOTTED_LEAD_DETAILS_PROFILE,
  //
  PROSPECTS,
  PROSPECT_DETAILS,
  PROSPECT_DETAILS_SUMMARY,
  PROSPECT_DETAILS_NOTES,
  PROSPECT_DETAILS_EVENT_LOGS,
  PROSPECT_DETAILS_TASKS,
  PROSPECT_MERGE,
  PROSPECT_DETAILS_FILES,
  PROSPECT_DETAILS_POSSIBLE_DUPLICATES,
  PROSPECT_DETAILS_SERVICES,

  DEALS,
  DEAL_DETAILS,
  DEAL_DETAILS_SUMMARY,
  DEAL_DETAILS_NOTES,
  DEAL_DETAILS_EVENT_LOGS,
  DEAL_DETAILS_TASKS,
  DEAL_MERGE,
  DEAL_DETAILS_FILES,
  DEAL_DETAILS_POSSIBLE_DUPLICATES,
  DEAL_DETAILS_SERVICES,
  //
  QUOTES,
  QUOTE_DETAILS,
  QUOTE_DETAILS_SUMMARY,
  QUOTE_DETAILS_FILES,
  QUOTE_DETAILS_NOTES,
  QUOTE_DETAILS_SERVICES,
  QUOTE_DETAILS_DOCUMENT,
  QUOTE_DETAILS_DOCUMENT_ID,
  QUOTE_DETAILS_ACTIVITY_LOGS,
  QUOTE_TASKS,
  QUOTE_DETAILS_EVENT_LOGS,
  ACCOUNTS,
  ACCOUNT_DETAILS,
  // //
  ACCOUNT_DETAILS_CONTACTS,
  ACCOUNT_DETAILS_BASIC_INFO,
  ACCOUNT_DETAILS_AGENTS,
  ACCOUNT_DETAILS_ADDRESSES,
  ACCOUNT_MERGE,
  ACCOUNT_POSSIBLE_DUPLICATES,
  ACCOUNT_DETAILS_ORDERS,
  ACCOUNT_DETAILS_EVENT_LOGS,
  ACCOUNT_FILES_AND_ATTACHMENTS,
  ACCOUNT_TASKS,
  ACCOUNT_DETAILS_NOTES,
  ACCOUNT_DETAILS_MERGE,
  //
  CONTACTS,
  CONTACT_DETAILS,
  CONTACT_MERGE,
  CONTACT_DETAILS_EVENT_LOGS,
  CONTACT_FILES_AND_ATTACHMENTS,
  CONTACT_TASKS,
  CONTACT_DETAILS_NOTES,
  CONTACT_DETAILS_MERGE,
  // //
  CONTACT_DETAILS_SUMMARY,
  CONTACT_DETAILS_ACCOUNTS,
  UNALLOTTED_CONTACTS,
  UNALLOTTED_CONTACT_DETAILS,
  UNALLOTTED_CONTACT_DETAILS_PROFILE,
  UNALLOTTED_CONTACT_DETAILS_ACCOUNTS,
  UNALLOTTED_CONTACT_DETAILS_QUOTES,
  //
  // SERVICE_AND_ORDERS,
  SERVICE_AND_ORDERS_BY_ORDERS,
  SERVICE_AND_ORDERS_BY_ORDERS_DETAILS,
  SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY,
  SERVICE_AND_ORDERS_BY_ORDERS_SERVICES,
  SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS,
  SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS,
  SERVICE_AND_ORDERS_BY_ORDERS_INVOICES,
  SERVICE_AND_ORDERS_BY_ORDERS_LOGS,
  SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT,
  SERVICE_AND_ORDERS_BY_ORDERS_FILES,
  SERVICE_AND_ORDERS_BY_ORDERS_TASKS,
  SERVICE_AND_ORDERS_BY_ORDERS_INVOICES_ID,
  PAYMENTS_RECEIPT,
  SERVICE_AND_ORDERS_BY_ORDERS_NOTES,
  SERVICE_AND_ORDERS_BY_ORDERS_ACTIVITY_LOGS,

  SERVICE_AND_ORDERS_BY_SERVICE,
  SERVICE_AND_ORDERS_BY_SERVICE_DETAILS,
  SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY,
  SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS,
  SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS,
  SERVICE_AND_ORDERS_BY_SERVICE_FILES,
  SERVICE_AND_ORDERS_BY_SERVICE_LOGS,
  SERVICE_AND_ORDERS_BY_SERVICE_TASKS,
  SERVICE_AND_ORDERS_BY_SERVICE_NOTES,
  SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL,
  SERVICE_AND_ORDERS_BY_SERVICE_DATA_COLLECTION,

  ACTIVITIES,
  ACTIVITY_DETAILS,
  ACTIVITY_DETAILS_SUMMARY,
  ACTIVITY_DETAILS_NOTES,
  ACTIVITY_DETAILS_REQUESTS,
  ACTIVITY_DETAILS_FILES,
  ACTIVITY_DETAILS_LOGS,
  ACTIVITY_DETAILS_TASKS,

  //
  PAYMENTS,
  PAYOUTS,

  INVOICES,
  INVOICES_DETAILS,
  //

  ENQUIRIES,
  ENQUIRIES_DETAILS,
  ENQUIRIES_DETAILS_SUMMARY,
  ENQUIRIES_DETAILS_REPLIES,
  //

  EXPORTS,

  //
  REPORTS,
  REPORT_CRE_PERFORMANCE_SUMMARY,
  REPORT_TEAM_PERFORMANCE_SUMMARY,
  REPORT_CRE_SERVICE_STATUS,
  NOTIFICATIONS,
  //

  SYSTEM_SETTINGS,
  SYSTEM_SETTINGS_ORGANIZATION,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES,
  SYSTEM_SETTINGS_FRANCHISEE,
  SYSTEM_SETTINGS_FRANCHISEE_DETAILS,
  SYSTEM_SETTINGS_FRANCHISEE_SUMMARY,
  SYSTEM_SETTINGS_FRANCHISEE_SERVICE,
  SYSTEM_SETTINGS_FRANCHISEE_EMPLOYEES,
  SYSTEM_SETTINGS_FRANCHISEE_PAYMENT_GATEWAY,
  SYSTEM_SETTINGS_FRANCHISEE_COMMUNICATION,
  SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT,
  SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_DETAILS,
  SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_BASIC_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS,

  SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT,
  SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER_CATEGORIES,
  SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_CHECKLIST,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_ACTIVITIES,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_STATUS,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_DATA_COLLECTION,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_ELIGIBILITY_CRITERIA,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DOCUMENT_REQUIRED,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_PROCESS,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_APPLICABILITY,
  SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_GUIDELINES,
  // SYSTEM_SETTINGS_PROMOTIONS,
  // SYSTEM_SETTINGS_PROMOTIONS_COUPONS,
  SYSTEM_SETTINGS_DATA_MANAGEMENT,
  SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS,
  SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_CONSTITUTION_CATEGORIES,
  SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_CATEGORIES,
  SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_BUSINESS_SECTOR,
  // SYSTEM_SETTINGS_FORMS,
  CONTACT_DETAILS_POSSIBLE_DUPLICATES,
  // SYSTEM_SETTINGS_FORMS_BUSINESS_FORMS,

  SHARE_DELIVERABLES,
  SHARE_DELIVERABLES_DETAILS,
  SHARE_DELIVERABLES_SUMMARY,
  SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_TRANSACTION,

  MY_TASKS_VIEW,
  MY_PAYMENT_APPROVAL_DETAILS,
  MY_APPROVAL_DETAILS,

  // SYSTEM SETTINGS VENDORS
  SYSTEM_SETTINGS_VENDORS,
  SYSTEM_SETTINGS_VENDOR_DETAILS,
  SYSTEM_SETTINGS_VENDOR_SUMMARY,
  SYSTEM_SETTINGS_VENDOR_SERVICE,
  SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT,
  SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_DETAILS,
  SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO,
  SYSTEM_SETTINGS_VENDOR_EMPLOYEES,
  SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS,
  SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO,
  SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO,

  SYSTEM_SETTINGS_VENDOR_ROLE,
  SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS,
  SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY,
  SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS,
  SYSTEM_SETTINGS_VENDOR_TEAM,
  SYSTEM_SETTINGS_VENDOR_TEAMDETAILS,
  SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY,
  SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS,
  // SYSTEM SETTINGS VENDORS
}
