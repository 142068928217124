import moment from 'moment'

import { InlineAutoComplete } from 'qbs-core'
import { getNestedProperty } from '../../utilities/parsers'
import { checkDetailPermission } from './permission'

const defaultColumnProps = {
  sortable: true,
  resizable: true,
  isVisible: true,
}
const defaultColumnProps1 = {
  isVisible: false,
  sortable: false,
  resizable: true,
}
const defaultColumnProps2 = {
  isVisible: false,
  sortable: true,
  resizable: true,
}
const defaultColumnProps3 = {
  isVisible: true,
  sortable: false,
  resizable: true,
}
export const getColumns = ({
  onViewAction,
  franchisees,
  handleFranchiseeeChange,
  updateKey,
}: any) => {
  const createRenderCell =
    (key: string, custom_data = '') =>
    (row: any) => {
      if (custom_data == 'service') {
        return {
          cell: (
            <>
              {row?.service?.slice(0, 2).map((item: any, index: number) => (
                <span key={item.id}>
                  {item.service_name}
                  {row?.service.length - 1 !== index && ', '}
                </span>
              ))}
              {row?.service?.length > 2 && (
                <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                  +{row.service.length - 2}
                </span>
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'followup') {
        const followup_date = moment(row?.next_followup_date)
        const yesterday = moment().subtract(1, 'day')
        const formattedDate = moment(row?.next_followup_date).format(
          'DD-MM-yyyy'
        )

        return {
          cell: (
            <>
              {yesterday.isAfter(followup_date) ? (
                <span className="text-red selected-item text-sm inline-block">
                  {row?.next_followup_date
                    ? formattedDate
                    : row?.next_followup_date}
                </span>
              ) : row?.next_followup_date ? (
                formattedDate
              ) : (
                row?.next_followup_date
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data === 'targetDate') {
        const target_date = moment(row?.target_date)
        const yesterday = moment().subtract(1, 'day')
        const formattedDate = moment(row?.target_date).format('DD-MM-yyyy')
        return {
          cell: (
            <>
              {yesterday.isAfter(target_date) ? (
                <span className="text-red selected-item text-sm inline-block">
                  {row?.target_date ? formattedDate : row?.target_date}
                </span>
              ) : row?.target_date ? (
                formattedDate
              ) : (
                row?.target_date
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data === 'fulldate') {
        const propertyValue = getNestedProperty(row, key)

        return {
          cell: (
            <>
              {propertyValue
                ? moment(propertyValue, 'MM/DD/yyyy hh:mm A').format(
                    'DD-MM-yyyy hh:mm A'
                  )
                : '--'}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data === 'franchisee') {
        const disabled = !checkDetailPermission('change', 'franchisee')
        return {
          cell: (
            <>
              {disabled ? (
                <>{row?.franchisee?.franchisee_display_name}</>
              ) : (
                <InlineAutoComplete
                  name="franchisee_id"
                  hideClose={true}
                  desc="franchisee_display_name"
                  key={updateKey}
                  data={franchisees}
                  descId="id"
                  type="custom_search_select"
                  placeholder={'Select Franchisee'}
                  onChange={(e) => handleFranchiseeeChange(e, row)}
                />
              )}
            </>
          ),
        }
      } else {
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
    }
  const column = [
    // {
    //   title: 'Lead ID',
    //   field: 'lead_number',
    //   ...defaultColumnProps,
    //   link: true,
    //   fixed: true,
    //   getPath: (row: any) => onViewAction(row, 'leadName'),
    //   sortKey: 'lead_number',
    // },

    {
      title: 'Lead Name',
      link: true,
      // fixed: true,
      getPath: (row: any) => onViewAction(row, 'leadName'),
      field: 'name',
      ...defaultColumnProps,
      sortKey: 'name',
    },

    // {
    //   title: 'Primary CRE',
    //   renderCell: createRenderCell('primary_cre.name'),
    //   field: 'primary_cre.name',
    //   ...defaultColumnProps,
    //   sortKey: 'primary_cre__user__first_name',
    // },
    {
      title: 'Business Name',
      // link: true,
      // rowClick: (row: any) => onViewAction(row, 'accountName'),
      field: 'business_name',
      ...defaultColumnProps,
      sortKey: 'business_name',
    },

    {
      title: 'Franchisee',
      field: 'Franchisee',

      renderCell: createRenderCell(
        'franchisee.franchisee_display_name',
        'franchisee'
      ),
      customCell: true,

      ...defaultColumnProps3,
    },
    {
      title: 'Campaign ID',
      field: 'campaign',
      ...defaultColumnProps3,
      sortable: true,
    },
    {
      title: 'Ad Set ID',
      field: 'ad_set_id',
      ...defaultColumnProps3,
      sortable: true,
    },
    {
      title: 'Ad ID',
      field: 'ad_id',
      ...defaultColumnProps3,
      sortable: true,
    },
    {
      title: 'Mobile 1',
      icon: true,
      field: 'phone',
      ...defaultColumnProps3,
      sortable: false,
    },
    {
      title: 'Mobile 2',
      renderCell: createRenderCell('lead_additional_data.phone2'),
      field: 'lead_additional_data.phone2',
      icon: true,
      ...defaultColumnProps1,
      sortable: false,
    },
    {
      title: 'Email 1',
      icon: true,
      field: 'email',
      ...defaultColumnProps3,
      sortable: false,
    },
    {
      title: 'Email 2',
      renderCell: createRenderCell('lead_additional_data.email2'),
      field: 'lead_additional_data.email2',
      icon: true,
      ...defaultColumnProps1,
      sortable: false,
    },
    {
      title: 'Language Pref',
      renderCell: createRenderCell(
        'lead_additional_data.preferred_language.language_name'
      ),
      field: 'lead_additional_data.preferred_language.language_name',
      ...defaultColumnProps,
      sortKey: 'lead_additional_data__preferred_language__language_name',
    },
    {
      title: 'Lead Source',
      icon: true,
      renderCell: createRenderCell('lead_source.name'),
      field: 'lead_source.name',
      ...defaultColumnProps,
      sortKey: 'lead_source__name',
    },
    {
      title: 'Sector',
      icon: true,
      renderCell: createRenderCell('sector.business_sector_display_name'),
      field: 'business_sector_display_name',
      ...defaultColumnProps2,
      sortKey: 'sector__business_sector_name',
      customCell: true,
    },
    {
      title: 'Industry',
      renderCell: createRenderCell('industry.business_industry_display_name'),
      field: 'business_industry_display_name',
      ...defaultColumnProps2,
      sortKey: 'industry__business_industry_name',
      customCell: true,
    },
    {
      title: 'Sub-Sector',
      renderCell: createRenderCell('sub_sector.business_industry_display_name'),
      field: 'business_industry_display_name',
      ...defaultColumnProps2,
      sortKey: 'sub_sector__business_industry_name',
      customCell: true,
    },
    {
      title: 'Interested Services',
      renderCell: createRenderCell('service', 'service'),
      colWidth: 500,
      field: '',
      ...defaultColumnProps,
      sortKey: 'service__service_name',
      customCell: true,
    },
    {
      title: 'Probability %',
      renderCell: createRenderCell('lead_additional_data.probability'),
      field: 'lead_additional_data.probability',
      ...defaultColumnProps,
      sortable: false,
    },
    {
      title: 'Lead Status',
      renderCell: createRenderCell('status.name'),

      field: 'status.name',
      ...defaultColumnProps3,
      sortable: false,
    },
    {
      title: 'Lead Quality',
      renderCell: createRenderCell('lead_quality.name'),
      field: 'lead_quality.name',
      ...defaultColumnProps3,
      sortable: false,
    },
    {
      title: 'Target Date',
      field: 'target_date',
      renderCell: createRenderCell('target_date', 'targetDate'),
      type: 'date',
      customCell: true,
      ...defaultColumnProps3,
      sortKey: 'target_date',
    },
    {
      title: 'Next Followup Date',
      field: 'next_followup_date',
      renderCell: createRenderCell('next_followup_date', 'followup'),
      type: 'date',
      customCell: true,
      sortKey: 'next_followup_date',
      ...defaultColumnProps2,
      // sortable: true,
    },
    {
      title: 'Supporting CRE',
      renderCell: createRenderCell('supporting_cre.name'),
      field: 'supporting_cre.name',
      customCell: true,
      ...defaultColumnProps1,
      sortable: false,
    },
    {
      title: 'Address 1: Line 1',
      renderCell: createRenderCell('lead_address.address_1'),
      field: 'lead_address.address_1',
      ...defaultColumnProps1,
      customCell: true,
      sortable: false,
    },
    {
      title: 'Address 1: line 2',
      renderCell: createRenderCell('lead_address.address_2'),
      field: 'lead_address.address_2',
      ...defaultColumnProps1,
      customCell: true,
      sortable: false,
    },
    {
      title: 'Adress 1: Country',
      renderCell: createRenderCell('lead_address.country'),
      field: 'lead_address.country',
      sortKey: 'lead_address__country',
      customCell: true,

      ...defaultColumnProps2,
    },
    {
      title: 'Address 1: State',
      renderCell: createRenderCell('lead_address.state'),
      field: 'lead_address.state',
      sortKey: 'lead_address__state',
      customCell: true,

      ...defaultColumnProps,
    },
    {
      title: 'Address 1: City',
      renderCell: createRenderCell('lead_address.city'),
      field: 'lead_address.city',
      ...defaultColumnProps3,
      customCell: true,
      sortable: false,
    },
    {
      title: 'Address 1: Pincode',
      renderCell: createRenderCell('lead_address.pin_code'),
      field: 'lead_address.pin_code',
      ...defaultColumnProps1,
      customCell: true,
      sortable: false,
    },

    // {
    //   title: 'Created Date',
    //   field: 'datetime_created',
    //   ...defaultColumnProps,
    //   type: 'text',
    //   renderCell: createRenderCell(
    //     "datetime_created","fulldate"
    //   ),
    //   customCell: true,
    //   sortKey: 'datetime_created',
    // },
    {
      title: 'Created  On',
      field: 'datetime_created',
      type: 'text',
      renderCell: createRenderCell('datetime_created', 'fulldate'),
      colWidth: 300,
      customCell: true,
      ...defaultColumnProps3,
      sortable: false,
    },
    {
      title: 'Updated On',
      field: 'datetime_updated',
      type: 'text',
      renderCell: createRenderCell('datetime_updated', 'fulldate'),
      colWidth: 300,
      customCell: true,
      ...defaultColumnProps3,
      sortable: false,
    },
    // {
    //   title: 'Created By',
    //   renderCell: createRenderCell('created_by.name'),
    //   field: 'created_by.name',
    //   ...defaultColumnProps,
    //   sortKey: 'created_by__user__first_name',
    // },

    {
      title: 'Updated By',
      renderCell: createRenderCell('updated_by.name'),
      field: 'updated_by.name',
      ...defaultColumnProps,
      colWidth: 300,
      sortKey: 'updated_by__user__first_name',
    },
  ]
  return column
}
